<template>
  <div>
    <spinner v-if="loading"></spinner>
    <div v-show="!loading" class="-m-t-xs">
      <div class="flex flex-wrap gap-xxs m-b-xs m-t-xs">
        <button @click="toggleMembership(membership)" class="btn btn-success btn-outline rounded-lg font-small"
                style="padding: 2px 6px;" v-for="membership in notDestroyedMemberships">
          {{ membership.roster.name }}
          <i class="fa fa-times"></i>
        </button>
        <button @click="toggleMembership(membership)" class="btn btn-danger btn-outline rounded-lg font-small"
                style="padding: 2px 6px;" v-for="membership in destroyedMemberships">
          {{ membership.roster.name }} (will be removed)
          <i class="fa fa-times"></i>
        </button>
      </div>
      <search
          class="search-form"
          response-key="rosters"
          placeholder="Enter roster name..."
          :mutate-history="false"
          :allow-add="false"
          only-dismiss-model="Roster"
          :small-input="true"
          :display-all-on-empty="false"
          readable-model-name="roster"
          :base-url="`/api/v1/rosters`">
        <template v-slot:list="slotProps">
          <div
              class="border-top m-t-sm m-b-xs p-t-sm p-b-xs cursor-pointer flex justify-space-between align-items-center p-r-sm"
              @click="addMembershipForRoster(slotProps.result)">
            <div>
              {{ slotProps.result.name }}
            </div>
            <div class="border rounded-sm border-blue-200 border-solid font-small"
                 style="padding: 2px 6px;">
              Add
            </div>
          </div>
        </template>
      </search>
      <div class="">
        <div class="grid grid-cols-2 gap-x-m m-t-sm" v-if="!autoSave">
          <button @click.prevent="$emit('cancel-roster-edit')" class="btn btn-default">Cancel</button>
          <ladda-button @lbClicked="updateWrestler()" el-class="btn-primary" :loading="loading">
            Save
          </ladda-button>
        </div>
        <div>
          <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Search from '../../shared/search.vue';
import { errorableMix } from './mixins/errorable_mix';
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'wrestler-roster-edit',
  components: { LaddaButton, Search, Spinner },
  mixins: [errorableMix],
  props: {
    wrestlerId: {
      type: Number,
      required: true
    },
    autoSave: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rosterMemberships: [],
      loading: true,
    };
  },
  mounted() {
    this.fetchRosterMemberships();
  },
  computed: {
    membershipsOrderedByRosterName() {
      return _.orderBy(this.rosterMemberships, ['roster.name']);
    },
    notDestroyedMemberships() {
      return this.membershipsOrderedByRosterName.filter(m => !m._destroy);
    },
    destroyedMemberships() {
      return this.membershipsOrderedByRosterName.filter(m => m._destroy);
    },
  },
  methods: {
    fetchRosterMemberships() {
      const url = this.$apiService.wrestlerRosterMembershipsUrl(this.wrestlerId);
      this.loading = true;
      axios.get(url)
          .then(response => {
            this.rosterMemberships = _.map(response.data.roster_memberships, (rm) => {
              rm._destroy = false;

              return rm;
            });
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errorMessage = `Error retrieving rosters ${error.toString()}. Please try again by refreshing the page or contact support@wrestlingiq.com`;
            this.error = true;
          });
    },
    addMembershipForRoster(roster) {
      const existingMembership = _.find(this.rosterMemberships, (rm) => rm.roster.id === roster.id);
      if (existingMembership) {
        existingMembership._destroy = false;
      } else {
        this.rosterMemberships.push({
          id: null,
          roster: roster,
          _destroy: false
        });
      }

      // Clear search results
      this.$notificationManager.$emit('profile-clicked', 'Roster');
      if (this.autoSave) {
        this.updateWrestler();
      }
    },
    toggleMembership(membership) {
      membership._destroy = !membership._destroy;
      if (this.autoSave) {  
        this.updateWrestler();
      }
    },
    updateWrestler() {
      // hit the /wrestlers update endpoint, nesting our array of membership inside of a key called wrestler_profile, and then the memberships inside of a key called roster_memberships_attributes
      const url = this.$apiService.wrestlerUrl(this.wrestlerId);
      const data = {
        wrestler_profile: {
          roster_memberships_attributes: _.map(this.rosterMemberships, (rm) => {
            return {
              id: rm.id,
              _destroy: rm._destroy,
              roster_id: rm.roster.id
            };
          })
        }
      };
      this.loading = true;
      axios.put(url, data)
          .then(() => {
            this.loading = false;
            this.$notificationManager.$emit('show-toast', 'Rosters updated', true);
            this.$emit('rosters-updated');
            if (this.autoSave) {
              this.fetchRosterMemberships();
            }
          })
          .catch((error) => {
            this.loading = false;
            this.errorMessage = `Error updating wrestler ${error.toString()}. Please try again by refreshing the page or contact support@wrestlingiq.com`;
          });
    }
  }
};
</script>
