<template>
    <div class="invite">
        <a class="btn btn-primary flex gap-x-xs align-items-center justify-center" :class="extraBtnClasses" v-if="!showingForm" @click="showFormClicked()"><i class="fa fa-paper-plane"></i><span>Invite {{ wrestlerFirstName }}</span></a>
        <div v-if="showingForm" role="form">
            <div class="form-group">
                <label>{{ wrestlerFirstName }}'s Email</label>
                <p class="text-sm text-gray-700">This should be <strong>{{ wrestlerFirstName }}'s</strong> email address. Parent accounts are created separately.</p>
                <input v-model="email" type="email" class="form-control">
            </div>
            <div class="button-area text-right invite">
                <div v-show="error">
                    <small>
                        <span class="text-danger">{{ errorMessage }}</span>
                    </small>
                </div>
                <button name="button" @click="hideFormClicked()" class="btn btn-default">Cancel</button>
                <ladda-button @lbClicked="postInvite" el-class="btn-primary" :loading="loading">
                    Send Invite
                </ladda-button>
            </div>
        </div>
    </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'invite',
  components: { LaddaButton },
  props: {
    wrestlerId: {
      type: Number,
      required: true,
    },
    wrestlerFirstName: {
      type: String,
      default: '',
    },
    reloadPage: {
      type: Boolean,
      default: false,
    },
    extraBtnClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      email: '',
      showingForm: false,
    };
  },
  methods: {
    hideFormClicked() {
      const vm = this;
      vm.showingForm = false;
      vm.$emit('hide');
    },
    showFormClicked() {
      const vm = this;
      vm.showingForm = true;
      vm.$emit('show');
    },
    validateNotEmpty(email) {
      const vm = this;

      if (email.length === 0) {
        vm.errorMessage = 'Please enter an email address.';
        vm.error = true;
        return false;
      }
      return true;
    },
    postInvite: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      if (vm.validateNotEmpty(vm.email)) {
        vm.loading = true;
        const params = {
          invite: {
            email: vm.email,
            inviteable_id: vm.wrestlerId,
            inviteable_type: 'WrestlerProfile',
          },
        };
        const url = vm.$apiService.invitesUrl();
        axios.post(url, params)
          .then((response) => {
            vm.loading = false;
            vm.showingForm = false;
            const { email } = response.data;
            vm.$parent.$emit('invite-success', email);
            vm.$notificationManager.$emit('show-toast', `${email} has been sent an invite`, true);
            if (vm.reloadPage) {
              window.location.reload();
            }
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support.';
            vm.error = true;
          });
      }
    }, 500),
  },
};
</script>
