<template>
    <div>
        <div v-if="showSearchForm" class="bg-gray-50 m-t-sm border rounded-sm border-solid border-gray-300 p-sm">
            <search
                    class="search-form"
                    response-key="results"
                    placeholder="Parent or coach name..."
                    :mutate-history="false"
                    :allow-add="false"
                    :display-all-on-empty="false"
                    readable-model-name="parent"
                    :guardians-only="true"
                    base-url="/api/v1/search">
                <template v-slot:list="slotProps">
                    <div class="profile-wrapper" @click="profileClicked(slotProps.result)">
                        <div class='search-result'>
                            <div class='hr-line-dashed'></div>
                            <h3>
                                <span class="selectable">{{ slotProps.result.name }}</span>
                            </h3>
                            <p>{{ slotProps.result.readable_type }}</p>
                        </div>
                    </div>
                </template>
            </search>
        </div>
        <div v-else-if="guardianableSelected && !showSearchForm" class="bg-gray-50 m-t-sm border rounded-sm border-solid border-gray-300 p-sm">
            <div role="form" class="guardian-associate">
                <div class="form-group">
                    <label>
                        Would you like to add {{ guardianableSelected.name }} as a parent/guardian to {{ wrestlerName }}?
                    </label>
                </div>
                <div class="flex gap-x-sm">
                    <div v-show="error">
                        <small>
                            <span class="text-danger">{{ errorMessage }}</span>
                        </small>
                    </div>
                    <button class="btn btn-default" @click="cancel()">Cancel</button>
                    <ladda-button @lbClicked="postGuardianRelationship" el-class="btn-primary" :loading="loading">
                        Yes, add parent
                    </ladda-button>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="btn btn-default align-items-center gap-x-xs justify-center" :class="extraBtnClasses">
                <a @click.prevent="showForm()"><i class="fa fa-link"></i> <span>Link Existing Parent / Guardian</span> </a>
            </div>
        </div>
    </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';
import Search from '../../shared/search.vue';

export default {
  name: 'associate-guardian',
  components: { Search, LaddaButton },
  props: {
    wrestlerProfileId: {
      type: Number,
      required: true,
    },
    wrestlerName: {
      type: String,
      required: true,
    },
    redirectUrl: {
      type: String,
      required: true,
    },
    extraBtnClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      guardianableSelected: null,
      showSearchForm: false,
    };
  },
  methods: {
    showForm() {
      this.showSearchForm = true;
    },
    profileClicked(profile) {
      this.guardianableSelected = profile;
      this.showSearchForm = false;
    },
    cancel() {
      this.guardianableSelected = null;
      this.showSearchForm = false;
    },
    postGuardianRelationship: _.throttle(function () {
      const vm = this;

      if (vm.loading) {
        return;
      }
      const params = {
        guardian_relationship: {
          guardianable_id: vm.guardianableSelected.id,
          guardianable_type: vm.guardianableSelected.type,
          wrestler_profile_id: vm.wrestlerProfileId,
        },
      };

      const url = vm.$apiService.guardianRelationshipUrl();
      axios.post(url, params)
        .then((response) => {
          vm.loading = false;
          vm.$notificationManager.$emit('show-toast', 'Added parent', true);
          window.location.assign(vm.redirectUrl);
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error, please try again later or contact support.';
          vm.error = true;
        });
    }, 500),
  },

};
</script>
