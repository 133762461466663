<template>
  <div :class="containerClass" class="guardian-invite-row">
    <div class="flex align-items-start justify-space-between">
      <div class="flex flex-wrap items-center gap-x-xs">
        <a v-if="showLink" :href="guardianUrl" class="text-gray-700">
          <h3 class="font-semi m-b-none m-t-none">{{ guardian.full_name }}</h3>
        </a>
        <h3 v-else class="font-semi m-b-none m-t-none">{{ guardian.full_name }}</h3>
        <span v-if="guestAccount" class="badge badge-info">Guest</span>
        <span v-else-if="active" class="badge badge-success">Active</span>
        <span v-else-if="invited" class="badge badge-warning">Invited</span>
      </div>
      <div v-if="showLink">
        <a :href="guardianUrl" class="text-gray-700 btn btn-compact btn-default text-10">
          View
        </a>
      </div>
    </div>
    <div v-if="active" class="text-gray-600">
      {{ guardian.email }}
    </div>
    <div v-if="invited" class="text-gray-600">
      {{ guardian.invite_email }}
    </div>
    <div v-if="showAccountType" class="text-gray-600">
      {{ guardian.type.replace('Profile', '') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuardianInviteRow',
  props: {
    guardian: {
      type: Object,
      required: true
    },
    containerClass: {
      type: String,
      default: 'border-y p-y-sm border-gray-50 border-solid'
    },
    showAccountType: {
      type: Boolean,
      default: false
    },
    showLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    guardianUrl() {
      if (this.guardian.type === 'CoachProfile') {
        return `/coaches/${this.guardian.id}`;
      } else if (this.guardian.type === 'ParentProfile') {
        return `/parents/${this.guardian.id}`;
      }
      return null;
    },
    active() {
      return this.guardian.user_status === 'active';
    },
    invited() {
      return this.guardian.user_status === 'invited';
    },
    guestAccount() {
      return this.guardian.profile_type === 'guest';
    }
  }
}
</script>
