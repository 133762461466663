import Vue from 'vue/dist/vue.esm';
import { initSentry } from '../shared/sentry_config';

// Initialize Sentry and get the instance
const Sentry = initSentry(Vue);

import toastr from 'toastr/build/toastr.min.js';
import { SeasonInterruption, GapMedical } from '@vertical-insure/web-components';
import swal from 'sweetalert2';
import VTooltip from 'v-tooltip';
import axiosSetup from './axiosSetup';
import validate from './config/validate.js';

import AgreementSignature from './vue/agreement_signature.vue';
import AnonymousRegForm from './vue/anonymous_reg_form.vue';
import Agreement from './vue/agreement.vue';
import AttendanceList from './vue/attendance_list.vue';
import Attachment from './vue/attachment.vue';
import AddGuestsForm from './vue/add_guests_form.vue';
import ApiService from './api_service.js';
import AssociateGuardian from './vue/associate_guardian.vue';
import ArchivePaidSession from './vue/archive_paid_session.vue';
import AccessibilityHelper from '../shared/accessibility_helper.js';
import BulkAddWrestlers from './vue/bulk_add_wrestlers.vue';
import BulkUpdateWrestlers from './vue/bulk_update_wrestlers.vue';
import BulkAddUsaw from './vue/bulk_add_usaw.vue';
import AllEventInviteList from './vue/all_event_invite_list.vue';
import BoutCreate from './vue/bout_create.vue';
import Chat from './vue/chat.vue';
import ChargeRow from './vue/charge_row.vue';
import ClassPassManager from './vue/class_pass_manager.vue';
import CouponForm from './vue/coupon_form.vue';
import CouponResult from './vue/coupon_result.vue';
import CoachSearchResult from './vue/coach_search_result.vue';
import CoachCreate from './vue/coach_create.vue';
import CoachEdit from './vue/coach_edit.vue';
import DecodedName from './vue/decoded_name.vue';
import DeactivateCoachForm from './vue/deactivate_coach_form.vue';
import DeactivateParentForm from './vue/deactivate_parent_form.vue';
import DocumentResult from './vue/document_result.vue';
import DocumentForm from './vue/document_form.vue';
import DonationManualInvoice from './vue/donation_manual_invoice.vue';
import DeleteDocumentForm from './vue/delete_document_form.vue';
import DeleteEventForm from './vue/delete_event_form.vue';
import DeleteMatchForm from './vue/delete_match_form.vue';
import DeleteWrestlerForm from './vue/delete_wrestler_form.vue';
import EditMessageMemberships from './vue/edit_message_memberships.vue';
import EditMessageGroupForm from './vue/edit_message_group_form.vue';
import EventCreate from './vue/event_create.vue';
import EventEdit from './vue/event_edit.vue';
import MatchPrefiller from './vue/match_prefiller.vue';
import EventInviteList from './vue/event_invite_list.vue';
import EventInviteOverview from './vue/event_invite_overview.vue';
import EventTime from './vue/event_time.vue';
import ExpandedWrestlerRow from './vue/expanded_wrestler_row.vue';
import FilteredWrestlerList from './vue/filtered_wrestler_list.vue';
import FilteredList from './vue/filtered_list.vue';
import GenerateWiqIdCard from './vue/generate_wiq_id_card.vue';
import GuardianInviteRow from './vue/guardian_invite_row.vue';
import InlineParentInvite from './vue/inline_parent_invite.vue';
import Calendar from './vue/calendar.vue';
import EventSearchResult from './vue/event_search_result.vue';
import FundraiserForm from './vue/fundraiser_form.vue';
import FundraiserManualInvoice from './vue/fundraiser_manual_invoice.vue';
import FundraiserResult from './vue/fundraiser_result.vue';
import FullLoadList from './vue/full_load_list.vue';
import Invite from './vue/invite.vue';
import InvoicePayment from './vue/invoice_payment.vue';
import EventInvite from './vue/event_invite.vue';
import EventRsvpForm from './vue/event_rsvp_form.vue';
import GenericSearchResult from './vue/generic-search-result.vue';
import KioskTokenCreate from './vue/kiosk_token_create.vue';
import LaddaButton from '../shared/ladda_button.vue';
import ManualInvoice from './vue/manual_invoice.vue';
import ManualInvoiceForm from './vue/manual_invoice_form.vue';
import MatchVideoMeta from './vue/match_video_meta.vue';
import MarkPaidForm from './vue/mark_paid_form.vue';
import MatchSearchResult from './vue/match_search_result.vue';
import MatchInvite from './vue/match_invite.vue';
import MatchScore from './vue/match_score.vue';
import MatchCheckInList from './vue/match_check_in_list.vue';
import MatchTagForm from './vue/match_tag_form.vue';
import MembershipForm from './vue/membership_form.vue';
import Mentionable from './vue/mentionable.vue';
import MentionableCreate from './vue/mentionable_create.vue';
import MergeJobResult from './vue/merge_job_result.vue';
import MergeJobForm from './vue/merge_job_form.vue';
import Messaging from './vue/messaging.vue';
import Message from './vue/message.vue';
import MetricsChart from './vue/metrics_chart.vue';
import MessageGroupForm from './vue/message_group_form.vue';
import MessageForm from './vue/message_form.vue';
import NotificationPreferences from './vue/notification_preferences.vue';
import OneOffInvoiceResult from './vue/one_off_invoice_result.vue';
import OnlineStoreForm from './vue/online_store_form.vue';
import OpposingWrestlerSearchResult from './vue/opposing_wrestler_search_result.vue';
import OpposingWrestlerCreate from './vue/opposing_wrestler_create.vue';
import OriginalVideo from './vue/original_video.vue';
import OnlineStoreSearchResult from './vue/online_store_search_result.vue';
import ParentCreate from './vue/parent_create.vue';
import PagedList from './vue/paged_list.vue';
import PaymentCharts from './vue/payment_charts.vue';
import RefundForm from './vue/refund_form.vue';
import RenewSubscription from './vue/renew_subscription.vue';
import RegistrationAnswers from './vue/registration_answers.vue';
import RegistrationInfoForm from '../shared/registration_info_form.vue';
import ResendInvite from './vue/resend_invite.vue';
import ParentEdit from './vue/parent_edit.vue';
import ParentSearchResult from './vue/parent_search_result.vue';
import PaidSessionForm from './vue/paid_session_form.vue';
import PaidSessionResult from './vue/paid_session_result.vue';
import PaymentPlan from './vue/payment_plan.vue';
import Pairings from './vue/pairings.vue';
import Payout from './vue/payout.vue';
import PracticePlanBlock from './vue/practice_plan_block.vue';
import PracticePlanBlockCategory from './vue/practice_plan_block_category.vue';
import PracticePlanDraggableCategories from './vue/practice_plan_draggable_categories.vue';
import PracticePlanSection from './vue/practice_plan_section.vue';
import PracticePlanTimeline from './vue/practice_plan_timeline.vue';
import PrintWiqIds from './vue/print_wiq_ids.vue';
import ProfileEventSubToggle from './vue/profile_event_sub_toggle.vue';
import ProductForm from './vue/product_form.vue';
import ProductListResult from './vue/product_list_result.vue';
import Registration from './vue/registration.vue';
import RegistrationForm from './vue/registration_form.vue';
import RegFormBuilder from './vue/reg_questions/reg_form_builder.vue';
import RecentDocSignAgain from './vue/recent_doc_sign_again.vue';
import RegYesNoQuestion from './vue/reg_questions/reg_yes_no_question.vue';
import RegAddressQuestion from './vue/reg_questions/reg_address_question.vue';
import RegDateQuestion from './vue/reg_questions/reg_date_question.vue';
import RegEmailQuestion from './vue/reg_questions/reg_email_question.vue';
import RegPhoneNumberQuestion from './vue/reg_questions/reg_phone_number_question.vue';
import RegShortTextQuestion from './vue/reg_questions/reg_short_text_question.vue';
import RegSingleSelectQuestion from './vue/reg_questions/reg_single_select_question.vue';
import RegMultiSelectQuestion from './vue/reg_questions/reg_multi_select_question.vue';
import RepeatedEventUpdate from './vue/repeated_event_update.vue';
import ReplicateSession from './vue/replicate_session.vue';
import ReportForm from './vue/report_form.vue';
import ReportViewer from './vue/report_viewer.vue';
import Roster from './vue/roster.vue';
import RosterCard from './vue/roster_card.vue';
import RosterFilter from './vue/roster_filter.vue';
import RosterForm from './vue/roster_form.vue';
import ReactivateWrestlerForm from './vue/reactivate_wrestler.vue';
import SavePracticePlan from './vue/save_practice_plan.vue';
import ScrambleWrestlerEdit from './vue/scramble_wrestler_edit.vue';
import Scoring from './vue/scoring.vue';
import ScoutingCard from './vue/scouting_card.vue';
import ScoutingQuestion from './vue/scouting_question.vue';
import Search from '../shared/search.vue';
import Signature from './vue/signature.vue';
import SignAgainForm from './vue/sign_again_form.vue';
import SignUpRow from './vue/sign_up_row.vue';
import Spinner from './vue/spinner.vue';
import SwalAlert from '../shared/swal_alert.vue';
import SwitchAccountForm from './vue/switch_account_form.vue';
import Subscription from './vue/subscription.vue';
import SubscriptionRow from './vue/subscription_row.vue';
import SubscriptionPlanDetails from './vue/subscription_plan_details.vue';
import SubscriptionSummary from './vue/subscription_summary.vue';
import TabRouter from './vue/tab_router.vue';
import Tag from "../shared/tag.vue";
import TeamMatchInvite from './vue/team_match_invite.vue';
import TeamEdit from './vue/team_edit.vue'
import TeamScore from './vue/team_score.vue';
import UpdateCc from './vue/update_cc.vue';
import UpsellForm from './vue/upsell_form.vue';
import UsawSettings from './vue/usaw_settings.vue';
import UsawManager from './vue/usaw_manager.vue';
import VerticalExpander from './vue/vertical_expander.vue';
import VideoUploader from './vue/video_uploader.vue';
import VideoPlayer from './vue/video_player.vue';
import VoidInvoiceForm from './vue/void_invoice_form.vue';
import WizardWrestlers from './vue/wizard_wrestlers.vue';
import WrestlerCreate from './vue/wrestler_create.vue';
import WrestlerCheckInList from './vue/wrestler_check_in_list.vue';
import WrestlerEdit from './vue/wrestler_edit.vue';
import WrestlerFullInfoCollector from './vue/wrestler_full_info_collector.vue';
import WrestlerSearchResult from '../shared/wrestler_search_result.vue';
import WrestlerInviteRow from './vue/wrestler_invite_row.vue';
import WrestlerNotesList from './vue/wrestler_notes_list.vue';
import WrestlerRosters from './vue/wrestler_rosters.vue';
import WrestlerRosterEdit from './vue/wrestler_roster_edit.vue';
import EventWithEventInvites from './vue/event_with_event_invites.vue';

// Third party tooling
import VueForceNextTick from 'vue-force-next-tick';
Vue.use(VueForceNextTick);

export default function () {
  document.addEventListener('DOMContentLoaded', () => {
    axiosSetup();
    // Setup vee-validate
    validate();

    const sentryMeta = document.querySelector('[name=sentry-id]');
    if (sentryMeta != null) {
      Sentry.setUser({ id: sentryMeta.content });
    }

    Vue.config.ignoredElements = ['trix-editor', 'justifi-card-form', 'justifi-billing-form', 'justifi-payment-method-form', 'justifi-payment-form', 'justifi-bank-account-form', 'season-interruption', 'gap-medical'];
    Vue.use(VTooltip, {
      themes: {
        'info-tooltip': {
          $extend: 'tooltip',
          triggers: ['hover', 'focus', 'click'],
          popperTriggers: ['hover', 'focus', 'click'],
          // Other options (see the 'Global options' section)
        },
      },
    });

    // TODO enhance this with vuex if this starts getting very complex
    const store = {
      debug: false,
      state: {
        prefilledEvent: null,
        prefilledWrestler: null,
      },
      setPrefilledWrestler(newWrestler) {
        if (this.debug) console.log('setprefilledwrestler triggered with', newWrestler);
        this.state.prefilledWrestler = newWrestler;
      },
      clearPrefilledWrestler() {
        if (this.debug) console.log('clearprefilledwrestler triggered');
        this.state.prefilledWrestler = null;
      },
      setPrefilledEvent(newEvent) {
        if (this.debug) console.log('setprefilledevent triggered with', newEvent);
        this.state.prefilledEvent = newEvent;
      },
      clearPrefilledEvent() {
        if (this.debug) console.log('clearprefilledevent triggered');
        this.state.prefilledEvent = null;
      },
    };

    // Create a global Event Bus
    // The event bus also handles global ajax polling (get unread message count)
    const NotificationManager = new Vue({
      data: {
        store,
        sharedState: store.state,
      },
    });

    const api = new ApiService();
    const access_helper = new AccessibilityHelper();

    // Add to Vue properties by exposing a getter for $bus
    Object.defineProperties(Vue.prototype, {
      $notificationManager: {
        get() {
          return NotificationManager;
        },
      },
      $apiService: {
        get() {
          return api;
        },
      },
      $srHelper: {
        get() {
          return access_helper;
        },
      },
    });

    const app = new Vue({
      el: '#app',
      components: {
        AddGuestsForm,
        AnonymousRegForm,
        Agreement,
        AgreementSignature,
        AllEventInviteList,
        Attachment,
        AssociateGuardian,
        AttendanceList,
        ArchivePaidSession,
        BulkAddWrestlers,
        BulkUpdateWrestlers,
        BulkAddUsaw,
        BoutCreate,
        Chat,
        ChargeRow,
        ClassPassManager,
        CouponForm,
        CouponResult,
        CoachSearchResult,
        CoachCreate,
        CoachEdit,
        DecodedName,
        DeactivateCoachForm,
        DeactivateParentForm,
        DeleteEventForm,
        DeleteMatchForm,
        DeleteDocumentForm,
        DeleteWrestlerForm,
        DocumentResult,
        DocumentForm,
        DonationManualInvoice,
        EditMessageGroupForm,
        EditMessageMemberships,
        EventCreate,
        EventEdit,
        EventInviteList,
        EventInviteOverview,
        EventRsvpForm,
        ExpandedWrestlerRow,
        FullLoadList,
        FilteredWrestlerList,
        FilteredList,
        FundraiserForm,
        FundraiserResult,
        FundraiserManualInvoice,
        GenerateWiqIdCard,
        Calendar,
        EventSearchResult,
        GenericSearchResult,
        KioskTokenCreate,
        Invite,
        InlineParentInvite,
        InvoicePayment,
        EventInvite,
        EventTime,
        LaddaButton,
        ManualInvoice,
        ManualInvoiceForm,
        MatchVideoMeta,
        MatchPrefiller,
        MarkPaidForm,
        MatchScore,
        MatchSearchResult,
        MatchTagForm,
        MatchInvite,
        MatchCheckInList,
        MembershipForm,
        Mentionable,
        MentionableCreate,
        MergeJobResult,
        MergeJobForm,
        MessageForm,
        MessageGroupForm,
        Messaging,
        Message,
        MetricsChart,
        NotificationPreferences,
        OpposingWrestlerCreate,
        OpposingWrestlerSearchResult,
        OneOffInvoiceResult,
        OnlineStoreForm,
        OnlineStoreSearchResult,
        OriginalVideo,
        Payout,
        ParentEdit,
        PracticePlanBlock,
        PracticePlanBlockCategory,
        PracticePlanDraggableCategories,
        PracticePlanSection,
        PracticePlanTimeline,
        PrintWiqIds,
        ProfileEventSubToggle,
        ProductForm,
        ProductListResult,
        PaymentCharts,
        RegistrationAnswers,
        RefundForm,
        RenewSubscription,
        RegistrationInfoForm,
        PagedList,
        Pairings,
        PaidSessionForm,
        ParentCreate,
        ParentSearchResult,
        PaidSessionResult,
        PaymentPlan,
        RecentDocSignAgain,
        Registration,
        RegistrationForm,
        RegFormBuilder,
        RegAddressQuestion,
        RegDateQuestion,
        RegEmailQuestion,
        RegPhoneNumberQuestion,
        RegShortTextQuestion,
        RegSingleSelectQuestion,
        RegMultiSelectQuestion,
        RegYesNoQuestion,
        RepeatedEventUpdate,
        ReplicateSession,
        ReportForm,
        ReportViewer,
        ResendInvite,
        ReactivateWrestlerForm,
        Roster,
        RosterCard,
        RosterFilter,
        RosterForm,
        SavePracticePlan,
        ScrambleWrestlerEdit,
        Scoring,
        ScoutingCard,
        ScoutingQuestion,
        Search,
        SignAgainForm,
        SignUpRow,
        Signature,
        Spinner,
        SwalAlert,
        SwitchAccountForm,
        Subscription,
        SubscriptionRow,
        SubscriptionPlanDetails,
        SubscriptionSummary,
        TabRouter,
        Tag,
        TeamEdit,
        TeamMatchInvite,
        TeamScore,
        UpdateCc,
        UpsellForm,
        UsawManager,
        UsawSettings,
        VerticalExpander,
        VideoUploader,
        VideoPlayer,
        VoidInvoiceForm,
        WizardWrestlers,
        WrestlerCreate,
        WrestlerCheckInList,
        WrestlerEdit,
        WrestlerSearchResult,
        WrestlerFullInfoCollector,
        WrestlerRosterEdit,
        GuardianInviteRow,
        WrestlerInviteRow,
        WrestlerNotesList,
        WrestlerRosters,
        EventWithEventInvites,
      },
      data() {
        return {
          message_interval: null,
          unread_message_count: null,
          pending_event_invites_count: null,
        };
      },
      mounted() {
        // Setup global toastr options
        toastr.options = {
          closeButton: false,
          debug: false,
          progressBar: false,
          preventDuplicates: false,
          positionClass: 'toast-top-right',
          showDuration: 400,
          hideDuration: 700,
          timeOut: 5000,
          extendedTimeOut: 10000,
          showEasing: 'swing',
          hideEasing: 'linear',
          showMethod: 'fadeIn',
          hideMethod: 'fadeOut',
        };

        // setup an ajax poll for retrieving the unread message count
        // only if we are a user
        const authType = document.querySelector('meta[name=ax-auth-type]').content;
        if (authType === 'user') {
          const vm = this;
          vm.checkForNotifications();

          vm.message_interval = setInterval((() => {
            vm.checkForNotifications();
          }), 15000);

          vm.$notificationManager.$on('message-read', vm.checkForNotifications);
          vm.$notificationManager.$on('event-invite-updated', vm.checkForNotifications);
          vm.$notificationManager.$on('show-toast', vm.showToast);
        }
      },
      destroyed() {
        const vm = this;
        clearInterval(vm.message_interval);
        vm.$notificationManager.$off('message-read', vm.checkForNotifications);
        vm.$notificationManager.$off('event-invite-updated', vm.checkForNotifications);
        vm.$notificationManager.$off('show-toast', vm.showToast);
      },
      methods: {
        showToast(message, success) {
          if (success) {
            toastr.success(message);
            return;
          }

          toastr.error(message);
        },
        checkForNotifications() {
          const vm = this;
          const url = '/api/v1/meta/notifications';
          axios.get(url)
            .then((response) => {
              vm.updateMsgCount(response.data.messages.unread_message_count);
              vm.updateEventInviteCount(response.data.calendar.pending_event_invites_count);
            })
            .catch((error) => {
              console.log('error getting meta');
            });
        },
        updateEventInviteCount(pendingCount) {
          // Update the count in the UI
          let vm = this;
          if (pendingCount !== vm.pending_event_invites_count) {
            // if this is actually a different count than before, send it off to everyone
            if (vm.pending_event_invites_count !== null) {
              // emit for other components to do what they will with this data
              vm.$notificationManager.$emit('pending-event-invite-count-changed', pendingCount);
            }

            // now update the components state
            vm.pending_event_invites_count = pendingCount;

            const selector = '#pending_event_invites_count';
            const badgeText = vm.pending_event_invites_count !== 0 ? pendingCount : '';
            $(selector).html(badgeText);
          }
        },
        updateMsgCount(msgCount) {
          // Update the count in the UI
          let vm = this;
          if (msgCount !== vm.unread_message_count) {
            // if this is actually a different count than before, send it off to everyone
            if (vm.unread_message_count !== null) {
              // emit for other components to do what they will with this data
              vm.$notificationManager.$emit('unread-message-count-changed', msgCount);
            }

            // now update the components state
            vm.unread_message_count = msgCount;

            const selector = '#unread_count';
            const iconSelector = '#icon_for_unread_count';
            if (vm.unread_message_count !== 0) {
              $(iconSelector).hide();
              $(selector).html(msgCount);
            } else {
              $(iconSelector).show();
              $(selector).html('');
            }
          }
        },
      },
    });
  });
}
