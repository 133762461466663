<template>
  <div class="row">
    <div class="col-xs-12">
      <p class="upper-label m-t-md m-b-none">
        <i class="fa fa-refresh"></i> Recurring Subscription
      </p>
      <h1 class="font-heavy text-18 m-t-xs m-b-none">
        {{ title }}
      </h1>
      <h2 class="font-semi text-16 m-t-xxs" v-html="subtitle">
      </h2>
      <div class="flex flex-wrap items-center m-t-m">
        <p class="m-r-xs" v-html="statusBadge"></p>
        <p class="" v-html="collectionBadge" v-if="collectionPaused"></p>
        <div class="" v-if="collectionPaused && subscription.days_remaining_when_resumed > 0">
          <p><span class="label label-warning-light">Days left on contract {{ subscription.days_remaining_when_resumed }}</span></p>
          <p class="m-t-none text-12">When you resume payments, the expiration date will be changed to ensure they receive their full, contracted membership length.</p>
        </div>
      </div>
      <hr/>
      <h3 class="m-b-sm font-heavy">Details</h3>
      <dl class="grid grid-cols-1 gap-4">
        <div v-if="hasCoupon" v-for="(coupon) in subscription.coupons" class="flex justify-space-between">
          <dt class="text-14 text-gray-600">
            Discount
          </dt>
          <dd>
            <span class="label label-plain bg-purple-600 text-purple-50">
              {{ coupon.code }}
            </span>
          </dd>
        </div>
        <div v-if="!hasCoupon" class="flex justify-space-between">
          <dt class="text-14 text-gray-600">
            Discount
          </dt>
          <dd>
            None
          </dd>
        </div>
        <div class="flex justify-space-between">
          <dt class="text-14">Billed to</dt>
          <dd v-html="parentName()"></dd>
        </div>
        <div class="flex justify-space-between">
          <dt class="text-14">Created</dt>
          <dd>{{ readableCreatedAt() }}</dd>
        </div>
        <div class="flex justify-space-between">
          <dt class="text-14 flex align-items-center">{{ endAtVerb }}
            <span v-if="endAtVerb === 'Next due'">
                  <VDropdown theme="info-tooltip">
                  <!-- This will be the popover target (for the events and position) -->
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-blue-600" style="margin-bottom: -2px; margin-left: 2px;">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                    </svg>
                                <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <div>
                      <p class="header">
                        When does WrestlingIQ actually charge the card?
                      </p>
                      <p>
                        Our subscription biling system usually runs once an hour, around the half hour mark. So if a subscription is due on 9:31am, it will be billed at 10:30am.
                      </p>
                    </div>
                  </template>
                </VDropdown>
            </span>
          </dt>
          <dd>{{ readableEndAt() }}</dd>
        </div>
        <div v-if="subscription.expires_on" class="flex justify-space-between">
          <dt class="text-14">Expires on</dt>
          <dd>{{ expiresOn() }}</dd>
        </div>
      </dl>
      <hr/>
      <div>
        <h3 class="m-b-sm font-heavy">Billing Partner ID</h3>
        <p v-if="subscription.billing_partner === 'stripe'">
          <a :href="`https://dashboard.stripe.com/subscriptions/${subscription.billing_partner_id}`" target="_blank">
            {{ subscription.billing_partner_id }}
          </a>
        </p>
        <p v-else>
          {{ subscription.billing_partner_id }}
        </p>
      </div>
      <hr/>
      <div>
        <h3 class="m-b-sm font-heavy">Edit</h3>
        <div class="flex flex-col gap-y-xs" v-if="paidOrOverdue && (!canceling && !showPauseConfirm && !showResumeConfirm && !showChangeCoupon && !showRemoveCoupon && !showChangePlan && !showDateChange)">
          <a class="btn btn-default flex gap-x-xs align-items-center justify-center" @click.prevent="showRemoveCoupon = true" v-if="allowCouponChange && !legacyUi && hasCoupon">
            <i class="fa fa-times"></i>
            <span>
                Remove discount
              </span>
          </a>
          <a class="btn btn-default flex gap-x-xs align-items-center justify-center" @click.prevent="showChangeCoupon = true" v-if="allowCouponChange && !legacyUi">
            <i class="fa fa-pencil"></i>
            <span>Change discount</span>
          </a>
          <a class="btn btn-default flex gap-x-xs align-items-center justify-center" @click.prevent="showChangePlan = true" v-if="allowPlanChange && !legacyUi">
            <i class="fa fa-shopping-cart"></i>
            <span>Change billing plan</span>
          </a>
          <template v-if="canRenew">
            <a class="btn btn-primary flex gap-x-xs align-items-center justify-center" :href="renewalUrl">
              <i class="fa fa-repeat"></i>
              <span>Renew</span>
            </a>
          </template>
          <template v-if="allowDateChange">
            <a class="btn btn-success flex gap-x-xs align-items-center justify-center" @click.prevent="showDateChange = true">
              <i class="fa fa-calendar"></i>
              <span>Change bill date</span>
            </a>
          </template>
          <template v-if="allowPause">
            <a class="btn btn-warning flex gap-x-xs align-items-center justify-center" v-if="collectionPaused" @click.prevent="showResumeConfirm = true">
              <i class="fa fa-play"></i>
              <span>Resume payments</span>
            </a>
            <a class="btn btn-warning flex gap-x-xs align-items-center justify-center" v-if="!collectionPaused" @click.prevent="showPauseConfirm = true">
              <i class="fa fa-pause"></i>
              <span>Pause payments</span>
            </a>
          </template>
          <template v-else>
            <a class="btn btn-warning flex gap-x-xs align-items-center justify-center" v-if="collectionPaused" @click.prevent="showPausePopup">
              <i class="fa fa-play"></i>
              <span>Resume payments</span>
            </a>
            <a class="btn btn-warning flex gap-x-xs align-items-center justify-center" v-if="!collectionPaused" @click.prevent="showPausePopup">
              <i class="fa fa-pause"></i>
              <span>Pause payments</span>
            </a>
          </template>
          <a class="btn btn-danger flex gap-x-xs align-items-center justify-center" @click.prevent="confirmCancel()">
            <i class="fa fa-times"></i>
            <span>Cancel subscription</span>
          </a>
        </div>
        <div v-if="showDateChange" class="archive-form should-dim modify-form m-t-sm card-bordered-dashed">
          <p class="text-16 font-semi">
            Change billing date
          </p>
          <p class="text-sm">
            <strong>Note: Changing the next billing date also changes future billing dates.</strong>
            E.g. If you have a monthly subscription and you change the next bill date to the 3rd, all future bills will happen on the 3rd of the month.
          </p>
          <div class="form-group">
            <label for="next_bill_date">Change next bill date to:</label>
            <input class="form-control"
                   v-model="nextBillDate"
                   id="next_bill_date"
                   type="datetime-local"
                   name="next_bill_date" />
          </div>
          <div class="">
            <button v-on:click="showDateChange = false" class="btn btn-default">Cancel</button>
            <ladda-button @lbClicked="changeBillDate" el-class="btn-warning btn" :loading="changingBillDate">
              Change bill date
            </ladda-button>
          </div>
        </div>
        <div v-if="showPauseConfirm" class="archive-form should-dim modify-form m-t-sm card-bordered-dashed">
          <p class="text-16 font-semi">
            Pause payment collection
          </p>
          <p class="text-sm">
            How long would you like to pause payment collection for?
          </p>
          <div>
            <label>
              <input type="radio" value="indefinite" id="indefinite-pause" v-model="pauseFor"> Indefinite
            </label>
          </div>
          <div>
            <label>
              <input type="radio" value="specific" id="specific-pause" v-model="pauseFor"> Until a specific date
            </label>
          </div>
          <div v-if="pauseFor === 'specific'" class="form-group">
            <label class="font-normal">Resume payment collection on</label>
            <div>
              <date-picker v-model="resumeAt" :date="resumeAt" :disabled-date="tomorrowOrLater"></date-picker>
            </div>
          </div>
          <div class="">
            <button v-on:click="showPauseConfirm = false" class="btn btn-default">Cancel</button>
            <ladda-button @lbClicked="pausePayments" el-class="btn-warning btn" :loading="pausing">
              Pause
            </ladda-button>
          </div>
        </div>
        <div v-if="showResumeConfirm" class="archive-form should-dim modify-form m-t-sm card-bordered-dashed">
          <p class="text-16 font-semi">
            Resume payment collection
          </p>
          <p class="text-sm">
            Are you sure you want to resume payment collection? Payments will be collected automatically on their next
            bill date.<br><br>
            <span v-if="subscription.payment_collection_resumes_at">
              Resuming now will override the previously set resume date of {{ formattedResumesAt }}.
            </span>
          </p>
          <div class="">
            <button v-on:click="showResumeConfirm = false" class="btn btn-default">Cancel</button>
            <ladda-button @lbClicked="resumePayments" el-class="btn-primary btn" :loading="resuming">
              Resume
            </ladda-button>
          </div>
        </div>
        <div v-if="showChangeCoupon" class="archive-form should-dim modify-form m-t-sm card-bordered-dashed">
          <p class="text-16 font-semi">
            Change discount
          </p>
          <p>
            <strong>Only 1 scholarship can be applied to a recurring membership at a time.</strong> If a scholarship code is already set on this subscription, it will be replaced.
          </p>
          <p class="text-sm">
          </p>
          <spinner v-if="couponsLoading"></spinner>
          <div v-if="!couponsLoading">
            <label>Which scholarship code do you want to apply to this membership?</label>
            <select v-model="couponToChangeTo" class="form-control" name="scholarship_code">
              <option v-for="c in availableCoupons" :value="c.id">
                {{ c.code }}
                <template v-if="c.amount_off > 0">
                  ({{ formatAsCurrency(c.amount_off) }})
                </template>
                <template v-else>
                  ({{ c.percent_off * 100 }}% off)
                </template>
              </option>
            </select>
          </div>
          <div class="m-t-m">
            <button v-on:click="showChangeCoupon = false" class="btn btn-default">Cancel</button>
            <ladda-button @lbClicked="changeCoupon" el-class="btn-primary btn" :loading="changingCoupon">
              Change
            </ladda-button>
          </div>
        </div>
        <div v-if="showRemoveCoupon" class="archive-form should-dim modify-form m-t-sm card-bordered-dashed">
          <p class="text-16 font-semi">
            Remove discount
          </p>
          <p>
            Do you want to remove <strong>{{ subscription.coupons[0].code }}</strong> from this membership?
          </p>
          <p class="text-sm">
          </p>
          <div class="m-t-m">
            <button v-on:click="showRemoveCoupon = false" class="btn btn-default">Cancel</button>
            <ladda-button @lbClicked="removeCoupon" el-class="btn-danger btn" :loading="changingCoupon">
              Remove
            </ladda-button>
          </div>
        </div>
        <div v-if="showChangePlan" class="archive-form should-dim modify-form m-t-sm card-bordered-dashed">
          <p class="text-16 font-semi">
              Change billing plan
          </p>
          <p>
              <strong>Price changes only impact future invoices.</strong> The member will be charged the new rate on their normal bill date.
          </p>
          <p class="text-sm">
          </p>
          <spinner v-if="loadingPlans"></spinner>
          <div v-if="!loadingPlans">
              <label>Which plan do you want to move this member to?</label>
              <select v-model="planToChangeTo" class="form-control m-b">
                  <optgroup v-for="(product, name) in availablePlans" :label="name" v-if="product.length > 0">
                      <option v-for="plan in product" v-bind:value="plan.id" :disabled="plan.id === membershipBillingPlan.id">
                          {{ plan.name }} <span v-if="plan.id === membershipBillingPlan.id">(current plan)</span>
                      </option>
                  </optgroup>
              </select>
          </div>
          <div class="m-t-m">
              <button v-on:click="showChangePlan = false" class="btn btn-default">Cancel</button>
              <ladda-button @lbClicked="changePlan" el-class="btn-primary btn" :loading="changingPlan">
                  Change
              </ladda-button>
          </div>
          </div>
        <p class="m-t-xs text-danger" v-html="errorMessage" v-if="error"></p>
      </div>
    </div>
  </div>
</template>
<script>
import { billingPlanMix } from './mixins/billing_plan_mix';
import DatePicker from './date_picker.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import Spinner from './spinner.vue';
import swal from 'sweetalert2';
import { errorableMix } from './mixins/errorable_mix';
import { formattingMixin } from './mixins/formatting_mix';

export default {
  name: 'subscription-summary',
  components: {
    DatePicker,
    Spinner,
    LaddaButton
  },
  mixins: [billingPlanMix, errorableMix, formattingMixin],
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    allowCancel: {
      type: Boolean,
      default: true,
    },
    allowPause: {
      type: Boolean,
      default: false,
    },
    allowCouponChange: {
      type: Boolean,
      default: false,
    },
    allowPlanChange: {
      type: Boolean,
      default: false,
    },
    allowDateChange: {
      type: Boolean,
      default: false,
    },
    canRenew: {
      type: Boolean,
      default: false,
    },
    contactEmail: {
      type: String,
      default: '',
    },
    // The Legacy UI is for the old multi-wrestler possible in a subscription format.
    // In the new UI, we can assume that there is only 1 wrestler per subscription
    legacyUi: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      canceling: false,
      showPauseConfirm: false,
      showResumeConfirm: false,
      showChangeCoupon: false,
      showRemoveCoupon: false,
      showChangePlan: false,
      showDateChange: false,
      couponToChangeTo: null,
      couponsLoading: false,
      changingCoupon: false,
      availableCoupons: [],
      availablePlans: [],
      planToChangeTo: null,
      loadingPlans: false,
      changingPlan: false,
      pausing: false,
      pauseFor: 'indefinite',
      resumeAt: null,
      resuming: false,
      nextBillDate: null,
      changingBillDate: false,
    };
  },
  computed: {
    title() {
      if (this.legacyUi || !this.wrestlerProfile) {
        return `Subscription ${this.subscription.billing_partner_id}`;
      }

      return `${this.wrestlerProfile.full_name}`
    },
    subtitle() {
      if (this.legacyUi) {
        return '';
      }

      if (this.membershipBillingPlan.product.paid_session_id) {
        return `<a href="/paid_sessions/${this.membershipBillingPlan.product.paid_session_id}">${this.membershipBillingPlan.product.name}</a>`;
      }

      return `${this.membershipBillingPlan.product.name}`;
    },
    endAtVerb() {
      switch (this.subscription.status) {
        case 'canceled':
          return 'Active until';
        case 'overdue':
          return 'Overdue since';
        default:
          return 'Next due';
      }
    },
    statusBadge() {
      switch (this.subscription.status) {
        case 'paid':
          return '<span class="label label-primary">Active</span>';
        case 'canceled':
          const word = this.subscription.expires_on ? 'Expired' : 'Canceled';
          return `<span class="label">${word}</span>`;
        case 'overdue':
          return '<span class="label label-warning">Overdue</span>';
        default:
          return '<span class="label">Unknown status</span>';
      }
    },
    formattedResumesAt() {
      if (this.subscription.payment_collection_resumes_at) {
        return moment(this.subscription.payment_collection_resumes_at)
            .format('M/D/YYYY');
      }

      return '';
    },
    collectionBadge() {
      if (this.subscription.payment_collection_resumes_at) {
        return `<span class="label whitespace-normal bg-red-600 text-red-50"><i class="fa fa-pause m-r-xs"></i>Payments paused until ${this.formattedResumesAt}</span>`;
      } else {
        return '<span class="label whitespace-normal bg-red-600 text-red-50"><i class="fa fa-pause m-r-xs"></i>Payments paused</span>';
      }
    },
    collectionPaused() {
      return this.subscription.payment_collection_status === 'paused';
    },
    // New UI shortcuts that work based on billing conventions of 1 wrestler per membership
    wrestlerProfile() {
      return _.head(_.uniqBy(_.flatMap(this.subscription.billing_plan_subscription_items, 'wrestler_profiles'), 'id'));
    },
    membershipBillingPlan() {
      return _.filter(this.subscription.billing_plans, { is_wiq_fee: false, is_tax_item: false })[0];
    },
    hasCoupon() {
      return this.subscription.coupons.length > 0;
    },
    renewalUrl() {
      return `/subscriptions/${this.subscription.billing_partner_id}/renew`;
    }
  },
  watch: {
    showChangeCoupon() {
      if (this.availableCoupons.length === 0) {
        this.loadCoupons();
      }
    },
    showChangePlan() {
      if (this.availablePlans.length === 0) {
        this.loadAvailablePlans();
      }
    }
  },
  methods: {
    loadAvailablePlans() {
      // We only allow changing to non expiring plans that have the same interval and interval count
      let ransack = {
        q: {
          interval_eq: this.subscription.interval,
          interval_count_eq: this.subscription.interval_count,
          intervals_to_bill_null: '1',
          active_true: '1',
        }
      }
      let url = this.$apiService.billingPlans(ransack);
      let vm = this;
      vm.$apiService.loadAllPages(url, 'billing_plans')
        .then((objects) => {
          vm.availablePlans = _.groupBy(_.filter(objects, function(p) { return p.is_wiq_fee === false && p.is_tax_item === false && p.product.name !== "Donation"}), 'product.name');
          vm.loadingPlans = false;
        })
        .catch((error) => {
          console.error(error);
          vm.errorMessage = 'Something went wrong loading available plans. Try again or email support@wrestlingiq.com';
          vm.error = true;
          vm.loadingPlans = false;
        });
    },
    loadCoupons() {
      const vm = this;
      const url = vm.$apiService.couponsUrl()
      vm.couponsLoading = true;
      vm.$apiService.loadAllPages(url, 'coupons')
          .then((objects) => {
            vm.availableCoupons = objects;
            vm.couponsLoading = false;
          })
          .catch((error) => {
            console.error(error);
            vm.errorMessage = 'Something went wrong loading available scholarship codes. Try again or email support@wrestlingiq.com';
            vm.error = true;
            vm.couponsLoading = false;
          });
    },
    // Func to disable certain dates in the date-picker
    tomorrowOrLater(date) {
      const tomorrow = new Date();
      // Set the hours to 0
      tomorrow.setHours(0, 0, 0, 0);
      // Bump the day up one
      tomorrow.setDate(tomorrow.getDate() + 1);
      return date < tomorrow;
    },
    confirmCancel() {
      const vm = this;

      if (this.allowCancel) {
        swal.fire({
          type: 'warning',
          title: 'Are you sure you want to cancel the entire subscription?',
          text: 'This will cancel all memberships in this subscription.',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonClass: 'btn btn-danger m-l-md',
          confirmButtonText: 'Yes, cancel subscription',
          cancelButtonText: 'No, keep subscription',
          cancelButtonClass: 'btn btn-default',
          buttonsStyling: false,
        })
            .then((result) => {
              if (result.value) {
                vm.cancelSub();
              }
            });
      } else {
        swal.fire({
          type: 'info',
          title: `Please contact ${this.contactEmail}`,
          text: `To cancel your membership please contact your coach or email ${this.contactEmail} directly.`,
          confirmButtonClass: 'btn btn btn-default',
          confirmButtonText: 'Ok',
          buttonsStyling: false,
        })
            .then((result) => {
            });
      }
    },
    cancelSub: _.throttle(function () {
      const vm = this;
      vm.error = false;
      vm.canceling = true;

      const deleteUrl = vm.$apiService.subscriptionUrl(this.subscription.id);
      axios.delete(deleteUrl)
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'Subscription canceled', true);
            vm.canceling = false;
            window.location.reload();
          })
          .catch((error) => {
            vm.errorMessage = 'Something went wrong canceling the subscription. Try again or email support@wrestlingiq.com';
            vm.error = true;
            vm.canceling = false;
          });
    }, 500),
    removeCoupon() {
      const vm = this;
      vm.error = false;
      vm.changingCoupon = true;

      const deleteCouponUrl = vm.$apiService.subscriptionDeleteCouponUrl(this.subscription.id);
      axios.delete(deleteCouponUrl)
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'Discount removed', true);
            vm.changingCoupon = false;
            window.location.reload();
          })
          .catch((error) => {
            vm.errorMessage = 'Something went wrong removing the discount. Try again or email support@wrestlingiq.com';
            vm.error = true;
            vm.changingCoupon = false;
          });
    },
    changeCoupon: _.throttle(function () {
      const vm = this;
      if (vm.changingCoupon) {
        return;
      }

      if (vm.couponToChangeTo == null) {
        vm.errorMessage = "Please select a scholarship code."
        vm.error = true;
        return;
      }

      vm.error = false;
      vm.changingCoupon = true;

      const changeCouponUrl = vm.$apiService.subscriptionCouponUrl(this.subscription.id);
      const couponParams = {
        billing_subscription: {
          coupon: {
            id: this.couponToChangeTo,
          }
        }
      };
      axios.post(changeCouponUrl, couponParams)
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'Scholarship code applied', true);
            vm.changingCoupon = false;
            window.location.reload();
          })
          .catch((error) => {
            vm.errorMessage = 'Something went wrong changing the scholarship code. Try again or email support@wrestlingiq.com';
            vm.error = true;
            vm.changingCoupon = false;
          });
    }, 500),
    changePlan: _.throttle(function () {
      const vm = this;

      if (vm.changingPlan || this.legacyUi) {
        return;
      }

      if (vm.planToChangeTo == null) {
        vm.errorMessage = "Please select a plan to change to."
        vm.error = true;
        return;
      }

      vm.error = false;
      vm.changingPlan = true;

      const changePlan = vm.$apiService.subscriptionChangePlanUrl(this.subscription.id);
      const planParams = {
        billing_subscription: {
          replace_plan: {
            plan_to_delete: this.membershipBillingPlan.id,
            plan_to_add: this.planToChangeTo,
          }
        }
      };
      axios.post(changePlan, planParams)
        .then((response) => {
          vm.$notificationManager.$emit('show-toast', 'Billing plan changed', true);
          vm.changingPlan = false;
          window.location.reload();
        })
        .catch((error) => {
          vm.errorMessage = `Something went wrong changing billing plans. Try again or email support@wrestlingiq.com ${error.toString()}`;
          vm.error = true;
          vm.changingPlan = false;
        });
    }, 500),
    showPausePopup() {
      swal.fire({
        type: 'info',
        title: `Please contact ${this.contactEmail}`,
        text: `To pause or resume payments contact your coach or email ${this.contactEmail} directly.`,
        confirmButtonClass: 'btn btn btn-default',
        confirmButtonText: 'Ok',
        buttonsStyling: false,
      })
          .then((result) => {
          });
    },
    pausePayments: _.throttle(function () {
      const vm = this;
      vm.error = false;
      if (vm.pauseFor === 'specific' && !vm.resumeAt) {
        vm.errorMessage = 'Please select a date to resume payment collection, or switch to an indefinite pause.';
        vm.error = true;
        return;
      }

      vm.pausing = true;

      const pauseUrl = vm.$apiService.subscriptionPausePaymentsUrl(this.subscription.id);
      const pauseParams = {
        billing_subscription: {
          pause_collection: {
            behavior: 'void',
            resumes_at: vm.resumeAt ? vm.resumeAt.toISOString() : null
          }
        }
      };
      axios.post(pauseUrl, pauseParams)
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'Payment collection paused', true);
            vm.pausing = false;
            window.location.reload();
          })
          .catch((error) => {
            vm.errorMessage = 'Something went wrong pausing payments. Try again or email support@wrestlingiq.com';
            vm.error = true;
            vm.pausing = false;
          });
    }, 500),
    resumePayments() {
      const vm = this;
      vm.error = false;
      vm.resuming = true;

      const resumeUrl = vm.$apiService.subscriptionResumePaymentsUrl(this.subscription.id);
      axios.post(resumeUrl, {})
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'Payment collection resumed', true);
            vm.resuming = false;
            window.location.reload();
          })
          .catch((error) => {
            vm.errorMessage = 'Something went wrong resuming payments. Try again or email support@wrestlingiq.com';
            vm.error = true;
            vm.resuming = false;
          });
    },
    changeBillDate: _.throttle(function () {
      const vm = this;
      vm.error = false;
      if (vm.changingBillDate || !vm.nextBillDate) {
        return;
      }
      vm.changingBillDate = true;

      // Send up the new billing date
      let tz = moment.tz.guess();
      let nextDate = moment.tz(vm.nextBillDate, tz).toISOString();

      const params = {
        billing_subscription: {
          current_period_end_at: nextDate
        }
      };
      const updateUrl = vm.$apiService.subscriptionUrl(this.subscription.id);
      axios.put(updateUrl, params)
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'Bill date changed', true);
            vm.changingBillDate = false;
            window.location.reload();
          })
          .catch((error) => {
            vm.errorMessage = this.parseErrorResponse(error);
            vm.error = true;
            vm.changingBillDate = false;
          });
    }, 500),
  },
};
</script>
