<template>
    <div class="block">
        <div class="flex flex-col border border-gray-100 rounded-sm border-solid hover:shadow-md transition-shadow duration-300">
          <a :href="linkUrl" class="block">
            <div class="relative video-cover-image" style="padding-top: 56.25%;">
                <img v-if="result.video && result.video.cover_image_url"
                      :src="result.video.cover_image_url"
                      :alt="matchTitle"
                     :id="`match-video-${result.video.id}`"
                      @error="handleImageError"
                      class="absolute inset-0 w-full h-full object-cover rounded-t-sm">
                <div v-show="showPlaceholder || !result.video" class="absolute inset-0 w-full h-full flex items-center justify-center bg-gray-100 rounded-t-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 text-gray-200">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z" />
                    </svg>
                </div>
                <div v-if="hasTaggedMatch(result)" @click.prevent.stop="showOptionsMenu = !showOptionsMenu" class="absolute bg-gray-900 text-gray-50 rounded-xxs p-x-xs p-y-xs text-10 flex flex-col gap-y-xxs btn-hover-visible" style="top: 8px; right: 8px;">
                    <i class="fa fa-pencil"></i>
                </div>
                <div v-if="decodedName()" class="absolute bg-gray-900 text-gray-50 rounded-sm p-x-xs p-y-xxs text-10 flex flex-col gap-y-xxs" style="bottom: 8px; right: 8px; margin-left: 8px;">
                  <span class="text-gray-50">{{ decodedName() }}</span>
                  <span v-if="result.tagged_match && result.tagged_match.event" class="text-gray-50">{{ result.tagged_match.event.name }}</span>
                  <span class="text-gray-50">Uploaded by {{ uploadedBy }}</span>
                  <span v-if="showStatusText(result)" class="text-gray-50">
                      <i class="fa fa-magic" aria-hidden="true"></i> Processing
                  </span>
                </div>
            </div>
            <div v-if="hasTaggedMatch(result)" class="flex align-items-center justify-space-between w-full">
                <div class="flex-grow-1">
                    <div class="flex justify-space-between p-y-xs p-x-m border-b border-solid border-gray-200">
                        <div class="flex gap-x-sm flex-wrap">
                          <span>{{ wrestlerName }}</span>
                          <span class="text-gray-400 text-uppercase">{{ wrestlerTeamName }}</span>
                        </div>
                        <div class="text-gray-700 relative">
                          {{ result.tagged_match.final_wrestler_score }}
                          <i v-if="isHomeWrestlerWinner" class="fa fa-caret-left text-gray-700 absolute" style="right: -16px; top: 50%; transform: translateY(-50%);"></i>
                        </div>
                    </div>
                    <div class="flex justify-space-between p-y-xs p-x-m" :class="{ 'border-b border-solid border-gray-200': showOptionsMenu }">
                      <div class="flex gap-x-sm flex-wrap">
                        <span class="font-bold">{{ opponentName }}</span>
                        <span class="text-gray-400 text-uppercase">{{ opponentTeamName }}</span>
                      </div>
                      <div class="font-bold relative">
                        {{ result.tagged_match.final_opponent_score }}
                        <i v-if="isOpponentWinner" class="fa fa-caret-left text-gray-700 absolute" style="right: -16px; top: 50%; transform: translateY(-50%);"></i>
                      </div>
                    </div>
                </div>
                <div class="align-self-stretch align-items-center flex border-solid border-gray-200 p-x-sm" :class="{ 'border-l': !showOptionsMenu, 'border-b-l': showOptionsMenu }">
                    <div class="text-gray-600" v-html="outcomeAbbreviation"></div>
                </div>
            </div>
          </a>
          <div v-if="currentlyUploading" class="m-t-sm">
            <div class="p-sm">
              <progress-bar :id="result.file_id" :hideBeforeStart="false" :hideOnComplete="false" :uploader="uploader" class="progress progress-bar-default"></progress-bar>
              <status :id="result.file_id" :uploader="uploader" class=""></status>
              <file-size :id="result.file_id" :uploader="uploader" class=""></file-size>
            </div>
            <div class="flex flex-wrap gap-sm justify-space-between align-items-center p-sm">
              <cancel-button :id="result.file_id" :uploader="uploader" class="btn btn-outline btn-danger btn-sm"></cancel-button>
              <pause-resume-button :id="result.file_id" :uploader="uploader" class="btn btn-outline btn-default btn-sm"></pause-resume-button>
              <retry-button :id="result.file_id" :uploader="uploader" class="btn btn-outline btn-default btn-sm"></retry-button>
            </div>
          </div>
          <div v-if="!hasTaggedMatch(result)" class="m-t-sm flex flex-wrap gap-sm justify-space-between align-items-center p-sm">
            <button @click="showMatchTagForm" class="btn btn-primary btn-sm">
              <i class="fa fa-tag m-r-xxs"></i> Tag Wrestler
            </button>
            <button v-if="!currentlyUploading" @click="showArchiveForm" class="btn btn-danger btn-sm">
              <i class="fa fa-archive m-r-xxs"></i> Archive Video
            </button>
          </div>
          <div v-if="showOptionsMenu" class="m-t-sm flex flex-wrap gap-sm justify-space-between align-items-center p-sm">
            <button type="button" @click="editing = true" class="btn btn-default btn-sm">
              <i class="fa fa-pencil m-r-xxs"></i> Edit Match
            </button>
            <button @click="showMatchDeleteForm" class="btn btn-danger btn-sm">
              <i class="fa fa-trash m-r-xxs"></i> Delete Match
            </button>
          </div>
          <div v-if="tagging" class="border border-solid border-gray-300 p-md rounded-b-sm bg-gray-50 m-t-sm">
            <h1 class="m-y-none m-b-sm font-heavy m-b-sm border-b border-solid border-gray-300 p-b-sm">Tag Wrestler</h1>
            <match-tag-form
              :allow-event-create="allowEventCreate"
              :prefilled-event="prefilledEvent"
              :prefilled-wrestler="prefilledWrestler"
              :original-video="result"
              v-on:cancel="hideMatchTagForm"
              :ruleset-slug="rulesetSlug"
              :unknown-opponent-id="unknownOpponentId"
            >
            </match-tag-form>
          </div>
          <div v-if="editing" class="border border-solid border-gray-300 p-md rounded-b-sm bg-gray-50 m-t-sm">
            <h1 class="m-y-none m-b-sm font-heavy m-b-sm border-b border-solid border-gray-300 p-b-sm">Edit Match</h1>
            <match-tag-form
              :original-video="result"
              :existing-match="result.tagged_match"
              :allow-event-create="allowEventCreate"
              :ruleset-slug="result.tagged_match ? result.tagged_match.ruleset : this.rulesetSlug"
              :unknown-opponent-id="unknownOpponentId"
              @cancel="editing = false"
              >
            </match-tag-form>
          </div>
          <div v-if="deleteMatchForm" class="border border-solid border-gray-300 p-md rounded-b-sm bg-gray-50 m-t-sm">
            <h1 class="m-y-none m-b-sm font-heavy m-b-sm border-b border-solid border-gray-300 p-b-sm">Delete Match</h1>
            <div class="m-t-sm">
              <delete-match-form v-if="deleteMatchForm" :match="result.tagged_match"></delete-match-form>
            </div>
          </div>
          <div v-if="archiveForm" class="border border-solid border-gray-300 p-md rounded-b-sm bg-gray-50 m-t-sm">
              <h1 class="m-y-none m-b-sm font-heavy m-b-sm border-b border-solid border-gray-300 p-b-sm">Archive Video</h1>
              <p class="m-t-sm">Archiving removes the originally uploaded video from this list and WrestlingIQ. Are you sure you want to archive?</p>
              <div class="mt-2">
                  <button @click="hideArchiveForm" class="btn btn-default btn-sm">Cancel</button>
                  <ladda-button @lbClicked="archivePressed" el-class="btn-danger btn-sm" :loading="loading">
                      Yes, Archive
                  </ladda-button>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
import CancelButton from 'vue-fineuploader/cancel-button.vue';
import FileSize from 'vue-fineuploader/filesize.vue';
import PauseResumeButton from 'vue-fineuploader/pause-resume-button.vue';
import ProgressBar from 'vue-fineuploader/progress-bar.vue';
import RetryButton from 'vue-fineuploader/retry-button.vue';
import Status from 'vue-fineuploader/status.vue';
import MatchTagForm from './match_tag_form.vue';
import DeleteMatchForm from './delete_match_form.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  components: {
    LaddaButton,
    DeleteMatchForm,
    MatchTagForm,
    CancelButton,
    FileSize,
    PauseResumeButton,
    ProgressBar,
    RetryButton,
    Status,
  },
  name: 'original-video',
  props: {
    result: {
      type: Object,
      required: true
    },
    currentlyUploading: {
      type: Boolean,
      default: false
    },
    uploader: {
      type: Object,
      required: false
    },
    allowEventCreate: {
      type: Boolean,
      default: false
    },
    rulesetSlug: {
      type: String,
      required: true
    },
    unknownOpponentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      tagging: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',
      archiveForm: false,
      deleteMatchForm: false,
      prefilledEvent: null,
      prefilledWrestler: null,
      showPlaceholder: false,
      editing: false,
      showOptionsMenu: false,
    };
  },
  created() {
    const vm = this;
    vm.$notificationManager.$on('hide-delete-match-form', vm.hideDeleteMatchForm);
    vm.$notificationManager.$on('match-deleted', vm.matchDeleted);
    vm.$notificationManager.$on('match-created', vm.matchTagChange);
    vm.$notificationManager.$on('match-updated', vm.matchTagChange);
    vm.$notificationManager.$on('prefilled-event-changed', vm.prefilledEventChange);
    vm.$notificationManager.$on('prefilled-wrestler-changed', vm.prefilledWrestlerChange);
    vm.prefilledEvent = vm.$notificationManager.$data.sharedState.prefilledEvent;
    vm.prefilledWrestler = vm.$notificationManager.$data.sharedState.prefilledWrestler;

    if (!this.hasTaggedMatch(this.result) && this.prefilledWrestler) {
      this.showMatchTagForm();
    }
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('hide-delete-match-form', vm.hideDeleteMatchForm);
    vm.$notificationManager.$off('match-deleted', vm.matchDeleted);
    vm.$notificationManager.$off('match-created', vm.matchTagChange);
    vm.$notificationManager.$off('match-updated', vm.matchTagChange);
    vm.$notificationManager.$off('prefilled-event-changed', vm.prefilledEventChange);
    vm.$notificationManager.$off('prefilled-wrestler-changed', vm.prefilledWrestlerChange);
  },
  computed: {
    uploadedBy() {
      return this.result.uploaded_by?.display_name ?? "Unknown";
    },
    wrestlerName() {
      if (this.hasTaggedMatch(this.result)) {
        const wrestler = this.result.tagged_match.wrestler;
        return `${wrestler.first_name.charAt(0)}. ${wrestler.last_name}`;
      }
      return "Unknown";
    },
    opponentName() {
      if (this.hasTaggedMatch(this.result) && this.result.tagged_match.opponent ) {
        const wrestler = this.result.tagged_match.opponent;
        return `${wrestler.first_name.charAt(0)}. ${wrestler.last_name}`;
      }
      return "U. Unknown";
    },
    wrestlerTeamName() {
      if (this.hasTaggedMatch(this.result) && this.result.tagged_match.wrestler?.team) {
        const name = this.result.tagged_match.wrestler.team.name;
        if (name.includes(' ')) {
          const initials = name.split(' ').map(word => word[0]).join('');
          return initials.length > 2 ? initials.slice(0, 3) : initials;
        } else {
          return name.slice(0, 3);
        }
      }
      return "Unk";
    },
    opponentTeamName() {
      if (this.hasTaggedMatch(this.result) && this.result.tagged_match.opponent?.team) {
        const name = this.result.tagged_match.opponent.team.name;
        if (name.includes(' ')) {
          const initials = name.split(' ').map(word => word[0]).join('');
          return initials.length > 2 ? initials.slice(0, 3) : initials;
        } else {
          return name.slice(0, 3);
        }
      }
      return "Unk";
    },
    matchTitle() {
      return `${this.wrestlerName} vs ${this.opponentName}`;
    },
    isOpponentWinner() {
      if (this.hasTaggedMatch(this.result) && this.result.tagged_match.opponent) {
        return this.result.tagged_match.winning_wrestler_profile_id === this.result.tagged_match.opponent.id;
      }
      return false;
    },
    isHomeWrestlerWinner() {
      if (this.hasTaggedMatch(this.result) && this.result.tagged_match.wrestler) {
        return this.result.tagged_match.winning_wrestler_profile_id === this.result.tagged_match.wrestler.id;
      }
      return false;
    },
    outcomeAbbreviation() {
      if (this.hasTaggedMatch(this.result)) {
        const outcomeMap = {
          "dec": "DEC",
          "m_dec": "MD",
          "tf": "TF",
          "fall": "FALL",
          "dq": "DQ",
          "default": "DEF",
          "ff": "FF"
        };
        return outcomeMap[this.result.tagged_match.outcome_slug] || "&nbsp;&nbsp;&nbsp;&nbsp;";
      }
      return "";
    },
    linkUrl() {
      return this.hasTaggedMatch(this.result)
        ? `/matches/${this.result.tagged_match.id}`
        : `/original_videos/${this.result.id}`;
    }
  },
  methods: {
    hideDeleteMatchForm(match) {
      if (this.result.tagged_match && this.result.tagged_match.id === match.id) {
        this.deleteMatchForm = false;
      }
    },
    matchDeleted(match) {
      if (this.result.tagged_match && this.result.tagged_match.id === match.id) {
        this.$set(this.result, 'tagged_match', null);
        this.deleteMatchForm = false;
      }
    },
    matchTagChange(match, originalVideo) {
      if (this.result.id === originalVideo.id) {
        this.updateTaggedMatch(match, originalVideo);
      }
    },
    prefilledEventChange(event) {
      this.prefilledEvent = event;
    },
    prefilledWrestlerChange(wrestler) {
      this.prefilledWrestler = wrestler;
    },
    updateTaggedMatch(match, originalVideo) {
      originalVideo.tagged_match = match;
      this.result = originalVideo;
      this.tagging = false;
      this.editing = false;
    },
    decodedName() {
      return decodeURIComponent(this.result.name);
    },
    hasTaggedMatch(result) {
      return result.tagged_match !== null;
    },
    showMatchTagForm() {
      this.tagging = true;
      this.$notificationManager.$emit('form-shown', 'OriginalVideo');
    },
    showMatchDeleteForm() {
      this.deleteMatchForm = true;
    },
    showStatusText(result) {
      return result.status === 'processing';
    },
    hideMatchTagForm() {
      this.tagging = false;
      this.$notificationManager.$emit('form-hidden', 'OriginalVideo');
    },
    showArchiveForm() {
      this.archiveForm = true;
    },
    hideArchiveForm() {
      this.archiveForm = false;
    },
    handleImageError() {
      this.showPlaceholder = true;
    },
    archivePressed: _.throttle(function () {
      if (this.loading) return;
      this.loading = true;

      const url = this.$apiService.originalVideoUrl(this.result.id);
      axios.delete(url)
        .then(() => {
          this.loading = false;
          this.$notificationManager.$emit('show-toast', `Archived ${this.result.name}`, true);
          this.$notificationManager.$emit('original-video-deleted', this.result);
        })
        .catch(() => {
          this.loading = false;
          this.errorMessage = 'Error, please try again later or contact support.';
          this.error = true;
        });
    }, 500),
  },
};
</script>
