<template>
  <div class="search-result">
    <div class='hr-line-dashed' v-if="!noHr"></div>
    <div class="grid sm-grid-cols-1 grid-cols-2 gap-x-sm">
      <div>
        <template v-if="showProducts">
          <div class="flex flex-col-xs-only gap-x-sm align-items-start">
            <div>
              <h3>
                {{ productNames() }}
              </h3>
              <div class="payment-plan-details">
                <p>
                  <span v-html="title"></span><br/>
                  <span v-html="parentName(true)"></span>
                </p>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <h3>
            <span class="text-default" v-html="title"></span>
          </h3>
          <div class="payment-plan-details">
            <p v-html="parentName()">
            </p>
          </div>
        </template>
      </div>
      <div>
        <div class="flex flex-col-xs-only gap-y-sm flex-wrap justify-space-between m-t-only-xs-sm">
          <div class="flex flex-col">
            <p class="m-t-none-important m-b-none-important font-semi">
              {{ formatAsCurrency(subscriptionTotal()) }}{{ subInterval() }}
            </p>
            <p class="m-t-none-important m-b-none-important">
              {{ nextDue() }}
            </p>
            <p class="m-t-none-important m-b-none-important">
              {{ since() }}
            </p>
            <p v-if="subscription.expires_on" class="m-t-none-important m-b-none-important">
              Expires {{ expiresOn() }}
            </p>
            <div class="flex gap-x-xs align-items-center m-t-xxs" v-if="coupon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-purple-600"><path fill-rule="evenodd" d="M1.5 6.375c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v3.026a.75.75 0 0 1-.375.65 2.249 2.249 0 0 0 0 3.898.75.75 0 0 1 .375.65v3.026c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 17.625v-3.026a.75.75 0 0 1 .374-.65 2.249 2.249 0 0 0 0-3.898.75.75 0 0 1-.374-.65V6.375Zm15-1.125a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-1.5 0V6a.75.75 0 0 1 .75-.75Zm.75 4.5a.75.75 0 0 0-1.5 0v.75a.75.75 0 0 0 1.5 0v-.75Zm-.75 3a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-1.5 0v-.75a.75.75 0 0 1 .75-.75Zm.75 4.5a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-.75ZM6 12a.75.75 0 0 1 .75-.75H12a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 12Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" clip-rule="evenodd"></path></svg>
              <p class="m-t-none-important m-b-none-important">
                <span class="text-purple-600 font-semi">{{ coupon.code }} applied</span>
              </p>
            </div>
          </div>
          <div class="flex flex-col gap-xs">
            <div class="flex flex-col gap-y-xxs" v-html="statusBadge"></div>
            <div v-html="collectionStatusBadge" v-if="!showProducts"></div>
            <a :href="`/subscriptions/${subscription.billing_partner_id}`"
                class="btn no-underline btn-compact bg-white border border-gray-400 text-gray-700">
          <span class="text-12">
              View Subscription
            </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formattingMixin } from './mixins/formatting_mix';
import { billingPlanMix } from './mixins/billing_plan_mix';

export default {
  name: 'subscription',
  mixins: [formattingMixin, billingPlanMix],
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    showProducts: {
      type: Boolean,
      default: false,
    },
    noHr: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    coupon() {
      return _.first(this.subscription?.coupons);
    },
    collectionStatusBadge() {
      if (this.subscription.payment_collection_status !== 'paused') {
        return "";
      }

      return '<span class="label bg-red-600 text-red-50">Paused<i class="fa fa-pause m-l-xs"></i></span>';
    },
    statusBadge() {
      switch (this.subscription.status) {
        case 'paid':
          return '<span class="label label-primary">Active</span>' + this.collectionStatusBadge;
        case 'canceled':
          return '<span class="label">Canceled</span>';
        case 'overdue':
          return '<span class="label label-warning">Overdue</span>' + this.collectionStatusBadge;
        default:
          return '<span class="label">Unknown status</span>' + this.collectionStatusBadge;
      }
    },
    title() {
      const members = [];
      const vm = this;
      this.subscription.billing_plan_subscription_items.forEach((si) => {
        const plan = vm.planForItem(si);
        if (plan && !plan.is_wiq_fee && !plan.is_tax_item) {
          si.wrestler_profiles.forEach((profile) => {
            const link = `<a href='/wrestlers/${profile.id}'>${profile.first_name} ${profile.last_name}</a>`;
            members.push(link);
          });
        }
      });
      return _.uniq(members).join(', ');
    },
  },
};
</script>
