<template>
  <div class="reactivate-wrestler">
    <a v-if="!showingForm" 
       class="btn btn-danger flex gap-x-xs align-items-center justify-center"
       @click="showingForm = true">
      <i class="fa fa-repeat"></i>
      <span>Reactivate Wrestler</span>
    </a>

    <div v-else class="bg-gray-50 m-t-sm border rounded-sm border-solid border-gray-300 p-sm">
      <h3 class="m-t-none m-b-sm">Reactivate Wrestler</h3>
      <p>
        This will restore their access to WrestlingIQ. You will need to manually re-add them to any rosters or messaging groups.
      </p>
      <div class="flex flex-col gap-y-xs">
        <ladda-button @lbClicked="reactivateWrestlerPressed" el-class="btn-danger btn-block w-full" :loading="loading">
          Yes, reactivate
        </ladda-button>
        <button v-on:click="showingForm = false" class="btn btn-default btn-block w-full">Cancel</button>
      </div>
      <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
    </div>
  </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
name: 'reactivate-wrestler-form',
components: { LaddaButton },
props: {
  wrestlerId: {
    type: Number,
    required: true
  }
},
data() {
  return {
    loading: false, // Loading is a network variable (hitting the api)
    showingForm: false,

    error: false,
    errorMessage: 'Error, please try again later or contact support.',
  };
},
methods: {
  hideReactivateForm() {
    const vm = this;
    vm.showingForm = false;
  },
  reactivateWrestlerPressed: _.throttle(function () {
    const vm = this;
    // If this is a duplicate request, just return
    if (vm.loading === true) {
      return;
    }
    vm.loading = true;

    const url = vm.$apiService.wrestlerReactivateUrl(vm.wrestlerId);
    axios.post(url)
      .then((response) => {
        vm.loading = false;

        vm.$notificationManager.$emit('show-toast', 'Wrestler is now active');
        window.location.reload();
      })
      .catch((error) => {
        vm.loading = false;
        vm.errorMessage = 'Error, please try again later or contact support.';
        vm.error = true;
      });
  }, 500),
},
};
</script>
