<template>
  <div :class="cardClasses">
    <div class="flex align-items-center justify-space-between">
      <div class="">
        <div class="flex flex-wrap gap-x-xs align-items-start">
          <div class="flex flex-col gap-y-xxs">
            <div>
              <h3 class="text-semi m-y-none">
                <a :href="'/wrestlers/' + wrestlerProfile.id " target="_blank">
                  {{ wrestlerProfile.full_name }}
                </a>
                <span v-if="saving" class="text-success">
                  <spinner :inline="true"></spinner>
                </span>
                <span v-if="error" class="text-danger">
                  Error saving attendance
                </span>
              </h3>
            </div>
            <div class="text-12 text-gray-600" v-html="wrestlerSubtitle"></div>
          </div>
          <div class="flex flex-wrap gap-x-xxs gap-y-xxs items-center">
            <tag
              v-for="tag in rosterTagsWithCount(wrestlerProfile)"
              :tag="tag"
              :key="tag.abbreviation"
              :count="tag.count"
            ></tag>
          </div>
        </div>
      </div>
      <div class="check-in-button flex-shrink-0">
        <div v-show="editingStatus && editable">
          <button
            :class="presentClasses"
            @click.prevent="statusTapped('present')"
          >
            <i class="fa fa-check"></i>
          </button>
          <div class="btn-group m-l-xs">
            <button
              type="button"
              class="btn btn-danger btn-outline dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-times"></i> <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li><a @click.prevent="statusTapped('excused')">Excused</a></li>
              <li>
                <a @click.prevent="statusTapped('unexcused')">Unexcused</a>
              </li>
              <li><a @click.prevent="statusTapped('late')">Late</a></li>
              <li role="separator" class="divider"></li>
              <li><a @click.prevent="statusTapped('injured')">Injured</a></li>
              <li><a @click.prevent="statusTapped('other')">Other</a></li>
            </ul>
          </div>
        </div>
        <div v-show="!editingStatus">
          <div class="">
            <span :class="statusTextStyle">
              <i :class="statusIcon"></i> {{ readableStatus(true) }}
            </span>
            <div class="btn-group link-btn" v-if="editable">
              <button
                data-toggle="dropdown"
                aria-expanded="false"
                class="btn btn-link icon-only dropdown-toggle"
              >
                <i aria-hidden="true" class="fa fa-caret-down"></i>
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <ul class="dropdown-menu">
                <template v-if="hasCustomQuestions">
                  <li>
                    <a @click.prevent="showQuestions = !showQuestions">
                      {{ showQuestions ? "Hide" : "View" }} additional info...
                    </a>
                  </li>
                  <li role="separator" class="divider"></li>
                </template>
                <li>
                  <a @click.prevent="editingNotes = true">
                    {{ this.checkInNotes ? "Edit Note..." : "Add Note..." }}
                  </a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                  <a @click.prevent="statusTapped('unknown')">Change...</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div>
          <div v-show="editingNotes">
            <div class="form-group">
              <div class="input-group">
                <label>Note</label>
                <textarea
                  v-model="draftNotes"
                  class="form-control"
                  rows="2"
                  style="resize: none"
                  name="draftNotes"
                  placeholder="Add notes here.."
                ></textarea>
              </div>
              <div class="button-area m-t-xs">
                <button
                  v-show="!saving"
                  type="button"
                  @click.prevent="editingNotes = false"
                  class="btn btn-white"
                >
                  Cancel
                </button>
                <ladda-button
                  @lbClicked="save"
                  :click-arg="true"
                  el-class="btn-primary"
                  :loading="saving"
                >
                  Save
                </ladda-button>
              </div>
            </div>
          </div>
          <p class="notes" v-show="checkInNotes && !editingNotes">
            Note: {{ checkInNotes }}
          </p>
        </div>
      </div>
    </div>
    <div class="row m-t-xs" v-if="showQuestions">
      <div class="col-sm-12">
        <div class="m-b-sm" v-html="checkedInAt"></div>
        <!-- little margin hack because the form component has padding we need to offset for styling -->
        <div class="m-r-n-sm m-l-n-sm xs-m-l-none xs-m-r-none">
          <registration-info-form
            profile-type="WrestlerProfile"
            :profile-id="wrestlerProfile.id"
            :check-in-id="checkInId"
            :specific-questions="questions"
            button-cta="Save"
            :endpoint="endpoint"
            :access-key="accessKey"
            :cloudfront-base-url="cloudfrontBaseUrl"
          ></registration-info-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "./spinner.vue";
import LaddaButton from "../../shared/ladda_button.vue";
import { checkInMix } from "./mixins/check_in_mix";
import RegistrationInfoForm from "../../shared/registration_info_form.vue";
import Tag from "../../shared/tag.vue";
import { taggableMix } from '../../shared/taggable_mix';

export default {
  name: "check-in-form",
  components: {
    Tag,
    LaddaButton,
    RegistrationInfoForm,
    Spinner,
  },
  mixins: [checkInMix, taggableMix],
  props: {
    wrestlerProfile: {
      type: Object,
      required: true,
    },
    checkIn: {
      type: Object,
      required: false,
    },
    eventId: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array,
      default: [],
    },
    endpoint: {
      type: String,
      required: false,
    },
    accessKey: {
      type: String,
      required: false,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: false,
    },
  },
  computed: {
    wrestlerSubtitle() {
      const weightClass = this.wrestlerProfile.weight_class || 'Unknown weight';
      const academicClass = this.wrestlerProfile.academic_class || '';
      if (!weightClass && !academicClass) return '';
      if (!weightClass) return academicClass;
      if (!academicClass) return weightClass;
      return `${weightClass}. ${academicClass}`;
    },
    statusTextStyle() {
      return {
        "text-primary": this.present,
        "text-warning": this.excused,
        "text-danger": this.absent,
      };
    },
    hasCustomQuestions() {
      return this.questions?.length > 0;
    },
    checkedInAt() {
      if (this.createdAt) {
        const time = moment(this.createdAt).format("M/D/YYYY, hh:mm a");
        return `Marked <strong>${_.lowerCase(
          this.readableStatus()
        )}</strong> at ${time}`;
      }

      return "Has not checked in";
    },
  },
  data() {
    // see CheckInMix for majority of data
    return {
      editingNotes: false,
      checkInNotes: "",
      draftNotes: "",
      showQuestions: false,
      classPass: null,
    };
  },
  created() {
    // CheckInMix also fires a syncing of props to data
    this.syncNotePropsToData();
    this.$notificationManager.$on(
      "registration-info-saved",
      this.questionsSaved
    );
  },
  mounted() {
    if (!this.editable) {
      this.editingStatus = false;
    }
  },
  destroyed() {
    this.$notificationManager.$off(
      "registration-info-saved",
      this.questionsSaved
    );
  },
  methods: {
    readableStatus(removePresent) {
      const status = this.checkInStatus.replace("_", " ");

      if (status === "present" && removePresent) {
        return "";
      }

      return _.capitalize(status);
    },
    capitalize(string) {
      return _.capitalize(string);
    },
    statusTapped(status) {
      const vm = this;
      if (!vm.editable) {
        return;
      }

      vm.checkInStatus = status;
      vm.save(false);
    },
    syncNotePropsToData() {
      if (this.checkIn) {
        this.checkInNotes = this.checkIn.notes || "";
        this.draftNotes = this.checkIn.notes || "";
      }
    },
    save: _.throttle(function (forNote) {
      const vm = this;

      if (vm.saving) {
        return;
      }

      vm.saving = true;
      vm.editingStatus = false;
      const params = {
        check_in: {
          wrestler_profile_id: vm.wrestlerProfileId,
          status: vm.checkInStatus,
          notes: vm.draftNotes,
        },
      };

      if (vm.checkInId) {
        vm.update(params, forNote);
      } else {
        vm.create(params, forNote);
      }
    }, 500),
    update(params, forNote) {
      const vm = this;
      const url = vm.$apiService.eventCheckInUrl(vm.eventId, vm.checkInId);
      axios
        .put(url, params)
        .then((response) => {
          vm.successHandler(response.data, forNote);
        })
        .catch((error) => {
          vm.errorHandler(error);
          vm.editingStatus = true;
        });
    },
    create(params, forNote) {
      const vm = this;
      const url = vm.$apiService.checkInsUrl(vm.eventId);
      axios
        .post(url, params)
        .then((response) => {
          vm.successHandler(response.data, forNote);
        })
        .catch((error) => {
          vm.errorHandler(error);
          vm.editingStatus = true;
        });
    },
    errorHandler(error) {
      const vm = this;
      vm.saving = false;
      vm.error = true;
    },
    successHandler(data, forNote) {
      const vm = this;
      if (data.id) {
        vm.checkInId = data.id;
        vm.createdAt = data?.created_at;
        vm.classPass = data?.class_pass;
      }
      vm.checkInNotes = vm.draftNotes;

      vm.$notificationManager.$emit(
        "wrestler-checked-in",
        vm.checkInId,
        vm.checkInStatus,
        vm.wrestlerProfile,
        vm.checkInNotes,
        vm.classPass,
      );
      let toastText = `${vm.wrestlerProfile.full_name} marked as ${_.lowerCase(
        vm.readableStatus(false)
      )}`;
      if (forNote) {
        toastText = `Note for ${vm.wrestlerProfile.full_name} saved`;
      }
      vm.$notificationManager.$emit("show-toast", toastText, true);
      vm.saving = false;
      vm.editingNotes = false;
      if (vm.checkInStatus === "unknown" && vm.editable) {
        vm.editingStatus = true;
      } else if (!forNote && this.hasCustomQuestions) {
        vm.showQuestions = true;
      }
    },
    questionsSaved(profileId, profileType) {
      if (
        profileType === "WrestlerProfile" &&
        profileId === this.wrestlerProfile?.id
      ) {
        this.showQuestions = false;
      }
    },
  },
};
</script>
