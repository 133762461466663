<template>
  <div class="m-b-xs">
    <div class="flex justify-space-between">
      <h2 class="upper-label m-b-xs m-t-none">
        Filter <span v-if="showSort">& Sort</span>
      </h2>
    </div>
    <div class="border-y border-gray-200 border-solid p-h-sm full-width-row bg-gray-50 gap-4 grid grid-cols-5 sm-grid-cols-1">
      <!-- Filters -->
      <div class="flex flex-col col-span-3">
        <form>
          <div>
            <button
                class="btn btn-none flex align-items-center justify-space-between p-w-xs p-l-n text-gray-400 bg-gray-50"
                @click.prevent="rosterFilters.open = !rosterFilters.open">
              <span class="text-gray-600">Roster</span>
              <span
                  class="m-l-sm -m-r-xxs font-normal border border-gray-300 border-solid rounded-xxs p-w-xs font-small text-gray-400"
                  v-if="selectedFilters.length > 0">{{ selectedFilters.length }}</span>
              <span class="m-l-xxs flex align-items-center">
                <svg :class="[rosterFilters.open ? 'rotate-180' : 'rotate-0']" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20" fill="currentColor" style="height: 18px; width: 18px;" aria-hidden="true"
                     class="text-gray-400">
                  <path fill-rule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clip-rule="evenodd"/>
                </svg>
          </span>
            </button>
          </div>
          <transition
              enter-active-class="transition ease-out duration-100"
              enter-class="opacity-0 translate-y-1"
              enter-to-class="opacity-0 translate-y-1"
              leave-active-class="transition ease-in duration-150"
              leave-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1"
          >
            <div v-show="rosterFilters.open" class="p-x-sm">
              <div class="grid grid-cols-2 md-grid-cols-1 gap-y-3 m-t-xxs">
                <div v-for="(option, optionIdx) in rosterFilters.options" :key="option.id"
                     class="flex align-items-center">
                  <input type="checkbox" :id="`filter-${optionIdx}`" v-model="option.checked"/>
                  <label :for="`filter-${optionIdx}`" class="m-l-xs text-small text-gray-600 font-normal m-b-none">{{
                      option.name
                    }}</label>
                </div>
              </div>
            </div>
          </transition>
        </form>
        <div class="flex flex-wrap gap-xxs m-t-xs m-b-xs" v-show="selectedFilters.length > 0">
          <button v-for="filter in selectedFilters" :key="filter.id" @click.prevent="filter.checked = false"
                  class="btn btn-success btn-outline rounded-lg font-small"
                  style="padding: 2px 6px;">
            {{ filter.name }}
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
      <!-- Sort -->
      <div v-if="showSort" class="form-group">
        <div>
          <label for="sort-filter" class="font-normal m-b-none">
            <span class="text-gray-600">Sort:</span>
          </label>
          <select v-model="sort" class="form-control filter-control m-l-xs xs-m-l-none p-x-xs" id="sort-filter">
            <option v-for="s in sortOptions" :value="s.value">{{ s.name }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { paramAndHistoryMixin } from './mixins/parameter_history_mix';

export default {
  name: 'roster-filter',
  mixins: [paramAndHistoryMixin],
  props: {
    mutateHistory: {
      type: Boolean,
      default: false,
    },
    showSort: {
      type: Boolean,
      default: false,
    },
    defaultSort: {
      type: String,
      default: 'weight',
    },
    defaultRosterIds: {
      type: Array,
      default() {
        return [];
      },
    },
    showArchived: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rosterFilters: {
        open: false,
        options: [],
      },
      sort: this.defaultSort,
      sortOptions: [
        {
          value: 'weight',
          name: 'Weight',
          checked: false,
        },
        {
          value: 'first_name',
          name: 'First name',
          checked: false,
        },
        {
          value: 'last_name',
          name: 'Last name',
          checked: false,
        },
      ]
    };
  },
  computed: {
    selectedFilters() {
      return _.filter(this.rosterFilters.options, { checked: true });
    },
    ransackQuery() {
      if (this.showArchived) {
        return { q: { s: 'name desc' } };
      } else {
        return { q: { archived_eq: false, s: 'name desc' } };
      }
    },
  },
  watch: {
    sort() {
      this.$notificationManager.$emit('sort-filter-applied', this.sort);
      localStorage.setItem('roster_sort_order', this.sort);
      if (this.mutateHistory) {
        this.replaceHistoryState('sort_by', this.sort);
      }
    },
    selectedFilters() {
      this.emitRosterFilterChange();
      if (this.mutateHistory) {
        this.replaceHistoryState('roster_ids', _.map(this.selectedFilters, 'id'));
        // Temp cleanup. Previous code threw down a single roster, which we no longer want to have in our query parameters
        this.replaceHistoryState('roster_id', null);
      }
    },
  },
  mounted() {
    try {
      const sortValue = localStorage.getItem('roster_sort_order');
      if (sortValue) {
        this.sortOptions.forEach((so) => {
          so.checked = so.value === sortValue;
          // not how this should really work, brain tired
          if (so.checked) {
            this.sort = so.value;
          }
        });
      }
    } catch (e) {
      // If we fail, assume this was corrupted client side, clear it out and return
      localStorage.removeItem('roster_sort_order');
    }
    this.getRosters();
    this.$notificationManager.$on('single-roster-filter-requested', this.attemptRosterFilter);
  },
  created() {
    // Normally this is hung off of the function definition itself;
    // however having two components on the same page appears to screw it up.
    // hooking it up inside the create appears to isolate it to the unique component
    this.emitRosterFilterChange = _.debounce(this.emitRosterFilterChange, 500);
  },
  destroyed() {
    this.$notificationManager.$off('single-roster-filter-requested', this.attemptRosterFilter);
  },
  methods: {
    attemptRosterFilter(roster) {
      let foundRoster = false;
      const rosters = _.map(this.rosterFilters.options, (r) => {
        if (r.id === roster.id) {
          r.checked = true
          foundRoster = true;
        }
        return r;
      });

      // The list does not preload with archived rosters in all cases, so sometimes we need to lazily load it
      if (!foundRoster) {
        // rewrite this to use the rostersUrl by id
        let url = this.$apiService.rosterUrl(roster.id);
        axios.get(url)
            .then((response) => {
              let newRoster = response.data;
              newRoster.checked = true;
              this.$set(this.rosterFilters, 'options', rosters.concat(newRoster));
            })
            .catch((error) => {
              console.log('failed to find roster by id');
            });
      }

      this.$set(this.rosterFilters, 'options', rosters);
    },
    emitRosterFilterChange() {
      this.$notificationManager.$emit('roster-filter-applied', this.selectedFilters);
    },
    getRosters() {
      const vm = this;
      const url = vm.$apiService.ransackRostersUrl(this.ransackQuery);
      vm.$apiService.loadAllPages(url, 'rosters')
        .then((objects) => {
          let rosters = _.map(objects, (r) => {
            r.checked = _.includes(vm.defaultRosterIds, r.id);
            return r;
          });
          
          // Set the options first
          vm.$set(vm.rosterFilters, 'options', rosters);
          
          // If we have default roster IDs, handle loading missing rosters
          if (vm.defaultRosterIds.length > 0) {
            const checkedRosterCount = _.filter(rosters, { checked: true }).length;
            if (checkedRosterCount !== vm.defaultRosterIds.length) {
              const missingRosters = _.difference(vm.defaultRosterIds, _.map(rosters, 'id'));
              if (missingRosters.length > 0) {
                return vm.$apiService.loadAllPages(
                  vm.$apiService.ransackRostersUrl({ q: { id_in: missingRosters } }), 
                  'rosters'
                ).then((missingObjects) => {
                  let missingRosters = _.map(missingObjects, (r) => {
                    r.checked = true;
                    return r;
                  });
                  const allRosters = rosters.concat(missingRosters);
                  vm.$set(vm.rosterFilters, 'options', allRosters);
                  vm.emitRosterFilterChange();
                });
              }
            }
          }
          
          // Always emit initial filter state
          vm.emitRosterFilterChange();
        })
        .catch((error) => {
          console.log('failed to find rosters');
          // Even on error, emit empty filter state
          vm.emitRosterFilterChange();
        });
    },
  },
};
</script>
