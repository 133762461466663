export const billingPlanMix = {
  data() {
    return {};
  },
  computed: {
    paidOrOverdue() {
      return this.subscription?.status === 'paid' || this.subscription?.status === 'overdue';
    },
  },
  methods: {
    planName(item) {
      const plan = this.planForItem(item);
      return _.get(plan, 'name', '');
    },
    // A billing subscription represents a singular subscription that can contain multiple billing plans
    planForItem(item, subscription = this.subscription) {
      if (item.billing_item_type !== 'BillingPlan') {
        return null;
      }
      return _.find(subscription.billing_plans, ['id', item.billing_item_id]);
    },
    productForItem(item, subscription = this.subscription) {
      const plan = _.find(subscription.billing_plans, { id: item.billing_item_id });
      return plan.product;
    },
    couponForItem(item, subscription = this.subscription) {
      return _.find(subscription.coupons, { id: item.billing_item_id });
    },
    productByBillingId(billingId, subscription = this.subscription) {
      // First grab a plan with this product (it's the same as a plan belongs to a product)
      const productId = parseInt(billingId);
      const plan = _.find(subscription.billing_plans, (bp) => bp.product.id === productId);

      return plan.product;
    },
    planPriceExtra(plan) {
      if (plan.interval_count !== 1) {
        return ` every ${plan.interval_count} ${plan.interval}s`;
      }

      return `/ ${plan.interval}`;
    },
    planDescription(plan) {
      if (!plan.intervals_to_bill) {
        // This is a recurring until canceled plan
        return `<p>Your card will be charged automatically until the subscription is canceled.</p>`;
      }

      let intervalExpiration = plan.interval_count * plan.intervals_to_bill;
      let pluralizedInterval = (plan.interval_count * plan.intervals_to_bill > 1) ? `${plan.interval}s` : plan.interval;
      return `<p>Subscription will expire ${intervalExpiration} ${pluralizedInterval} after signing up.`
    },
    isDonation(plans) {
      if (!plans) {
        return false;
      }

      const donationPlans = _.filter(plans, (p) => {
        const name = _.toLower(p.name);
        return name.includes('donor') || name.includes('donation');
      });

      return donationPlans.length > 0;
    },
    parentName(html= true, subscription = this.subscription) {
      const billable = _.get(subscription, 'billing_profile.billable');
      if (billable) {
        if (billable.user_status === 'inactive') {
          return `${billable.display_name} (inactive)`;
        }

        if (billable.type === 'Customer') {
          return _.get(subscription, 'billing_profile.email');
        }

        if (!html) {
          return billable.display_name;
        }

        if (billable.type === 'ParentProfile') {
          return `<a href='/parents/${billable.id}'>${billable.display_name}</a>`;
        } else if (billable.type === 'CoachProfile') {
          return `<a href='/coaches/${billable.id}'>${billable.display_name}</a>`;
        }
      }

      return 'Unknown';
    },
    wrestlerNames(subscription = this.subscription) {
      const members = [];
      subscription.billing_plan_subscription_items.forEach((si) => {
        const plan = this.planForItem(si, subscription);
        if (plan && !plan.is_wiq_fee && !plan.is_tax_item) {
          si.wrestler_profiles.forEach((profile) => {
            members.push(`${profile.first_name} ${profile.last_name}`);
          });
        }
      });
      return _.uniq(members).join(', ');
    },
    nextDue(subscription = this.subscription) {
      const nextDue = this.readableEndAt(subscription);
      if (subscription.status === 'canceled') {
        return `Active until ${nextDue}`;
      } if (subscription.status === 'paid') {
        return `Next due ${nextDue}`;
      }
      return 'Unknown';
    },
    readableEndAt(subscription = this.subscription) {
      const tz = moment.tz.guess();
      if (subscription.expires_on) {
        // write a boolean that checks whether expires_on is within two days of current_period_end_at
        const isLastPeriod = moment(subscription.expires_on).diff(moment(subscription.current_period_end_at), 'days') <= 2;
        if (isLastPeriod) {
          return 'N/A - all cycles billed';
        }
      }

      return subscription.current_period_end_at ? moment(subscription.current_period_end_at).tz(tz).format('M/D/YYYY hh:mm a') : 'unknown';
    },
    readableCreatedAt(subscription = this.subscription) {
      const tz = moment.tz.guess();
      return moment(subscription.created_at).tz(tz).format('M/D/YYYY hh:mm a');
    },
    since(subscription = this.subscription) {
      const plans = _.get(this, 'invoicePayment.billing_subscription.billing_plans', null);
      const noun = this.isDonation(plans) ? 'Recurring donor since' : 'Created at';
      return `${noun} ${this.readableCreatedAt(subscription)}`;
    },
    expiresOn(subscription = this.subscription) {
      if (!subscription.expires_on) {
        return null;
      }
      const tz = moment.tz.guess();
      return `${moment(subscription.expires_on).tz(tz).format('M/D/YYYY hh:mm a')}`;
    },
    // To get at a subscription total for this particular subscription we grab all of the
    // 'clean' billing plans for the other functions to use, ignoring coupons and wiq fees
    cleanBillingItems(subscription = this.subscription) {
      const items = [];
      const vm = this;
      subscription.billing_plan_subscription_items.forEach((si) => {
        const plan = vm.planForItem(si, subscription);
        if (plan && !plan.is_wiq_fee && !plan.is_tax_item) {
          items.push(si);
        }
      });

      return items;
    },
    cleanPlanNames(subscription = this.subscription) {
      const names = [];
      this.cleanBillingItems(subscription).forEach((item) => {
        const plan = this.planForItem(item, subscription);
        if (plan) {
          names.push(plan.name);
        }
      });

      return _.uniq(names).join(", ");
    },
    productNames(subscription = this.subscription) {
      const vm = this;
      const products = _.map(this.cleanBillingItems(subscription), (item) => vm.productForItem(item, subscription).name);

      return _.join(products, ', ');
    },
    subscriptionTotal(subscription = this.subscription) {
      const vm = this;
      const items = vm.cleanBillingItems(subscription);
      // First add up all the plans
      let total = _.sumBy(items, (item) => vm.totalForSubscriptionItem(item, subscription));
      // Then we do some coupon magic
      const coupon = _.first(subscription.coupons);
      if (coupon) {
        if (coupon.amount_off > 0) {
          total -= coupon.amount_off;
        } else {
          total -= coupon.percent_off * (total);
        }
      }

      return total;
    },
    totalForSubscriptionItem(item, subscription = this.subscription) {
      const plan = this.planForItem(item, subscription);
      let total = plan.amount * item.quantity;

      const feePlan = _.find(subscription.billing_plans, ['id', plan.associated_fee_id]);
      if (feePlan) {
        total += (feePlan.amount * item.quantity);
      }

      const taxPlan = _.find(subscription.billing_plans, ['id', plan.associated_tax_id]);
      if (taxPlan) {
        total += (taxPlan.amount * item.quantity);
      }

      return total;
    },
    subInterval(subscription = this.subscription) {
      const plan = _.first(subscription.billing_plans);
      return this.planPriceExtra(plan);
    },
  },
};
