<template>
  <div>
    <div v-if="!tagsOnly" class="flex align-items-baseline">
      <h3 class="m-b-sm m-t-none font-heavy"><span v-if="showEdit">Editing </span>Rosters</h3>
      <a v-if="canEdit && !showEdit" class="m-l-auto" @click.prevent="showEdit = true">
        <i class="fa fa-pencil color-gray-400 m-r-xxs"></i>Edit
      </a>
    </div>
    <div v-if="!showEdit">
      <div class="flex flex-wrap gap-x-xxs gap-y-xxs items-center m-y-none">
        <tag
            v-for="tag in rosterTagsWithCount(wrestlerProfile)"
            :tag="tag"
            :key="tag.abbreviation"
            :count="tag.count"
        ></tag>
        <a v-if="canEdit && showPencil && !showEdit" @click.prevent="showEdit = true">
          <i class="fa fa-pencil color-gray-400"></i>
        </a>
      </div>
      <div v-if="!tagsOnly" class="m-t-xxs">
        <p>
          {{ activeRosterNames(wrestlerProfile) }}
        </p>
        <div v-if="archivedRosters(wrestlerProfile).length > 0">
          <p v-if="!showingArchivedRosters">
            <a @click.prevent="showingArchivedRosters = !showingArchivedRosters">
              {{ archivedRosters(wrestlerProfile).length }} archived roster{{ archivedRosters(wrestlerProfile).length > 1 ? 's' : '' }} <i class="fa fa-angle-down"></i>
            </a>
          </p>
          <div v-else-if="showingArchivedRosters">
            <h3 class="m-b-sm m-t-none font-heavy">
              <a @click.prevent="showingArchivedRosters = !showingArchivedRosters" class="flex align-items-center gap-xs">
                <span>Archived rosters</span> <i class="fa fa-angle-up"></i>
              </a>
            </h3>
            <p>
              {{ archivedRosterNames(wrestlerProfile) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="tagsOnly" class="text-gray-600">
        Editing rosters for {{ wrestlerProfile.display_name }}
      </div>
      <wrestler-roster-edit
          :wrestler-id="wrestlerProfile.id"
          @cancel-roster-edit="showEdit = false"
          @rosters-updated="refreshWrestlerProfile"
      ></wrestler-roster-edit>
    </div>
    <p v-if="error">
      <small class="text-danger">{{ errorMessage }}</small>
    </p>
  </div>
</template>
<script>
import Tag from '../../shared/tag.vue';
import { taggableMix } from '../../shared/taggable_mix';
import { errorableMix } from './mixins/errorable_mix';
import WrestlerRosterEdit from './wrestler_roster_edit.vue';

export default {
  name: 'wrestler-rosters',
  components: {
    WrestlerRosterEdit,
    Tag
  },
  mixins: [taggableMix, errorableMix],
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    showPencil: {
      type: Boolean,
      default: true,
    },
    forWrestlerProfile: {
      type: Object,
      required: true,
    },
    tagsOnly: {
      type: Boolean,
      default: false,
    },
    defaultExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showEdit: false,
      wrestlerProfile: {},
      showingArchivedRosters: false,
    };
  },
  created() {
    this.wrestlerProfile = this.forWrestlerProfile;
    this.showEdit = this.defaultExpanded;
  },
  methods: {
    refreshWrestlerProfile() {
      // use Axios to hit the wrestler show endpoint with expand_rosters paramter set to true, then set wrestlerprofile to the result

      const url = this.$apiService.expandedRostersWrestlerUrl(this.wrestlerProfile.id);
      axios.get(url)
          .then(response => {
            this.wrestlerProfile = response.data;
            this.showEdit = false;
          })
          .catch((error) => {
            this.error = true;
            this.errorMessage = error.response.data.error;
          });
    },
  }
};
</script>
<style scoped>
</style>
